import React from 'react'
import '../../css/destino.css'
import { Outlet, Link } from "react-router-dom";
import promociones from '../../assets/images/cta/promociones.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/pregutas.png'
import ImageSlider from '../sliders/SliderDestinos';
import Footer from '../Footer'
import { useTranslation } from 'react-i18next';
import {img1Mochis, img2Mochis, img3Mochis} from '../../assets/base64'
export default function Mochis() {
    const { t } = useTranslation('mochis');
    const images = [

        {
            image: img1Mochis,
            title: 'Pueblo Mágico El Fuerte',
            text: 'Este poblado ofrece grandes atractivos naturales, históricos, arquitectónicos y culturales además de arraigadas tradiciones indígenas yoremes. ',
            subtext: 'Los Mochis, Sinaloa.',
        },
        {
            image: img2Mochis,
            title: 'El Chepe',
            text: 'A bordo del ferrocarril “El Chepe” podrás apreciar los increíbles paisajes de la sierra tarahumara. Su ruta de 653 kilómetros atraviesa las “Barrancas del Cobre”, una serie de cañones únicos en su tipo. ',
            subtext: 'Los Mochis, Sinaloa.',
        },
        {
            image: img3Mochis,
            title: 'Jardín Botánico Benjamín Francís Johnston',
            text: 'Es el pulmón ecológico de Los Mochis, que además de dotar a la ciudad de una belleza escénica, resguarda colecciones documentadas de plantas con propósitos de estudio científico, conservación, exhibición y educación.',
            subtext: 'Los Mochis, Sinaloa.',
        },
        // Agrega más imágenes según sea necesario
      ];
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Los Mochis, Sialoa.</h3>
                        <p>{t('mochis')}</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <ImageSlider images={images}/>
        </section>
        <section>            
            <div className='container'>
                <div className='destino_info_util'>
                    <div className='content_destinos_info'>
                        <div className='box_destinos_info'>
                            <h3>{t('texto1')}</h3>
                            <ul>
                                <li><strong>{t('texto2')}: </strong>{t('texto3')}</li>
                                <li><strong>{t('texto4')}: </strong>{t('texto5')}</li>
                                <li><strong>{t('texto6')}: </strong>{t('texto7')}</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>{t('texto8')}</h3>
                            <ul>
                                <li><strong>{t('texto9')}: </strong>{t('texto10')} </li>
                                <li><strong>{t('texto11')}: </strong> {t('texto12')}</li>
                                <li><strong>{t('texto13')}: </strong>{t('texto14')}</li>
                            </ul>
                        </div>
                    
                        <div className='box_destinos_info'>
                            <h3>{t('texto15')}</h3>
                            <label>{t('texto16')}</label>
                            <ul>
                                <li><strong>{t('texto17')} </strong>{t('texto18')}</li>
                                <li><strong>{t('texto19')}: </strong>{t('texto20')}</li>
                                <li><strong>{t('texto21')} </strong>{t('texto22')}</li>
                                <li><strong>{t('texto23')}: </strong>{t('texto24')}</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>{t('texto25')}</h3>
                            <ul>
                                <li><strong>{t('texto26')}:</strong>{t('texto27')}</li>
                                <li><strong>{t('texto28')} </strong>{t('texto29')}</li>
                                <li><strong>{t('texto30')} </strong>{t('texto31')} </li>                       
                            </ul>
                        </div>

                    </div>
                    <div className='content_destinos_info'>
                       
                        <div className='box_destinos_info'>
                            <h3>{t('texto32')}</h3>
                            <label>{t('texto33')}</label>
                            <ul>
                                <li><strong>{t('texto34')}: </strong>{t('texto35')}</li>
                                <li><strong>{t('texto36')}: </strong>{t('texto37')}</li>
                                <li><strong>{t('texto38')}: </strong>{t('texto39')}</li>
                                <li><strong>{t('texto40')}: </strong>{t('texto41')}</li>                            
                                <li><strong>{t('texto42')}: </strong>{t('texto43')}</li>
                            </ul>
                        </div>
                        
                        <div className='box_destinos_info'>
                            <h3>{t('texto44')}</h3>
                            <label>{t('texto45')}:</label>
                            <ul>
                                <li><strong>{t('texto46')}: </strong>{t('texto47')}</li>
                                <li><strong>{t('texto48')}: </strong>{t('texto49')}</li>
                                <li><strong>{t('texto50')}: </strong>{t('texto51')}</li>                       
                            </ul>
                            <label>{t('texto52')}:</label>
                            <ul>
                                <li>{t('texto53')}</li>                   
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Ver tarifas</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Promociones y Descuentos</h4>
                            </div>
                            <div>
                                <p>Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <Link to="/promociones">Ir ahora</Link>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
        <Footer />
    
    
    </>
  )
}

import React from 'react'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import '../css/certificaciones.css'
import lrqa9001 from '../assets/images/certificaciones/9001.jpg'
import lrqa1401 from '../assets/images/certificaciones/14001.jpg'
import distintivoH from '../assets/images/certificaciones/distintivo-h.png'
import esr from '../assets/images/certificaciones/esr.png'
import esr_inclusion from '../assets/images/certificaciones/esr-inclusion.png'
import prime from '../assets/images/certificaciones/prime.png'
//import puntolimpio from '../assets/images/certificaciones/punto-limpio.png'
import safe_travel from '../assets/images/certificaciones/safe-travel.png'
import etica_valores from '../assets/images/certificaciones/etica-valores.png'
import imss from '../assets/images/certificaciones/imss.png'

import mejores_empresas from '../assets/images/certificaciones/mejores-empresas.png'
import mejores_practicas from '../assets/images/certificaciones/mejores-practicas.jpg'
import empresas_excepcionales from '../assets/images/certificaciones/empresas-excepcionales.png'

import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next';

export default function Certificaciones() {
    const { t } = useTranslation('certificacion');
  return (
    <>

    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('certifications_title')}</h3>
                    <p style={{textAlign:'center'}}>{t('certifications_description')}</p>
                </div>                
            </div>
        </div>
    </section>


    <section className='certificaciones'>
        <section className='container'>
            <div className='certificaciones_list'>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={lrqa9001} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h1')} </label>
                    <p>{t('certificaciones_box_p1')}</p>
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={lrqa1401} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h2')}</label>
                    <p>{t('certificaciones_box_p2')}</p>
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={prime} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h3')}</label>
                     <p>{t('certificaciones_box_p3')}</p>
                </div>                
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={esr} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h4')}</label>
                    <p>{t('certificaciones_box_p4')}</p>
                     {/*<p>Otorgado por: Centro Mexicano para la Filantropía (CEMEFI) y la Alianza por la Responsabilidad Social Empresarial (AliaRSE)</p>*/}
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={esr_inclusion} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h5')}</label>
                    <p>{t('certificaciones_box_p5')}</p>
                    {/*<p>Otorgado por: Centro Mexicano para la Filantropía (CEMEFI) y la Alianza por la Responsabilidad Social Empresarial (AliaRSE)</p>*/}
                </div>
 
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={distintivoH} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h6')}</label>
                    <p>{t('certificaciones_box_p6')}</p>
                    {/*<p>Otorgado por: Secretaría de Turismo en colaboración con la Secretaría de Salud del Gobierno Federal</p>*/}
                </div>
                
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={safe_travel} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h7')}</label>
                    <p>{t('certificaciones_box_p7')}</p>
                    {/*<p>Otorgado por: entidades gubernamentales y/o organizaciones turísticas nacionales en colaboración con el Consejo Mundial de Viajes y Turismo (WTTC)</p>*/}
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={mejores_practicas} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h8')}</label>
                    <p>{t('certificaciones_box_p8')}</p>
                    {/*<p>Otorgado por: Secretaría de Turismo, avalado por la Secretaría de Salud y la Secretaría del Trabajo y Previsión Social</p>*/}
                
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={etica_valores} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h9')}</label>
                    <p>{t('certificaciones_box_p10')}</p>
                    {/*<p>Otorgado por: Secretaría de Turismo en colaboración con la Secretaría de Salud del Gobierno Federal</p>*/}
                </div>
                
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={imss} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h10')}</label>
                    <p>{t('certificaciones_box_p10')}</p>
                    {/*<p>Otorgado por: entidades gubernamentales y/o organizaciones turísticas nacionales en colaboración con el Consejo Mundial de Viajes y Turismo (WTTC)</p>*/}
                </div>
                
              
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={mejores_empresas} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h11')}</label>
                    <p>{t('certificaciones_box_p11')}</p>
                    {/*<p>Otorgado por: Secretaría de Turismo, avalado por la Secretaría de Salud y la Secretaría del Trabajo y Previsión Social</p>*/}
                
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={empresas_excepcionales} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>{t('certificaciones_titulo_h12')}</label>
                    <p>{t('certificaciones_box_p12')}</p>
                    {/*<p>Otorgado por: Secretaría de Turismo en colaboración con la Secretaría de Salud del Gobierno Federal</p>*/}
                </div>

            </div>
         </section>
    </section>
    <section className='callToAction'>
            <div className="container" id="content">
            <div className='title_callToAction'>
                <h3>¿Listo para reservar?</h3>
            </div>
        <Row>
        <Col span={8}>
                <div className='box_group-card'>
                    <div className='box_content-card contact-box-card'>
                        <div className='box_icon-card'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='boxes_content'>
                            <div className='box_content-title'>
                                <h2>Consulta nuestras tarifas</h2>
                            </div>
                            <div className='box_content-subtext'>
                                <p>
                                    Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                </p>
                            </div>
                            <div className='box_content-btn'>
                                <Link to="?">Reservar ahora</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Col>   
            <Col span={8}>
                <div className='box_group-card'>
                    <div className='box_content-card contact-box-card'>
                        <div className='box_icon-card'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='boxes_content'>
                            <div className='box_content-title'>
                                <h2>Preguntas frecuentes</h2>
                            </div>
                            <div className='box_content-subtext'>
                                <p>
                                    Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                </p>
                            </div>
                            <div className='box_content-btn'>
                                <Link to="?">Consultar </Link>                                      
                            </div>
                        </div>
                    </div>
                </div>
            </Col>      
            <Col span={8}>
                <div className='box_group-card'>
                    <div className='box_content-card contact-box-card'>
                        <div className='box_icon-card'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='boxes_content'>
                            <div className='box_content-title'>
                                <h2>Envianos un WhatsApp</h2>
                            </div>
                            <div className='box_content-subtext'>
                                <p>
                                    Te responderemos lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                </p>
                            </div>
                            <div className='box_content-btn'>
                                <Link to="?">Enviar WhatsApp </Link>                                  
                            </div>
                        </div>

                    </div>
                </div>
            </Col>        
                  
        </Row>    
        </div>
    <Outlet />
        </section>
    <Footer />
    </>
  )
}

import React, {useEffect, useState} from 'react'
import { useNavigate,  } from 'react-router-dom';
import '../css/noticia_list.css'

export default function TaskList() {
    const [noticias, setTasks] = useState([]);
    const navigate = useNavigate();
 
    const handleDelete = async (id) => {

    try {
        //await fetch(`http://localhost:3001/noticias/${id}`,{
            await fetch(`https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/noticias/${id}`,{
            
            method: 'DELETE',
        })

        setTasks(noticias.filter(noticias => noticias.id !== id));
        } catch (error) {
            console.log(error)
        }

    }

    const loadTasks = async () => {
        //const response = await fetch('http://localhost:3001/noticias');
        const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/noticias');
        
        const data = await response.json();
        console.log("Data: ",data);
        setTasks(data);
    }

    useEffect(() =>{
        loadTasks();
    },[])

    const handleNew = () => {
        navigate("/noticias/new");

    };
      

    console.log("Noticia: ",noticias);
    return (
        <>
         <section className='noticia_list'>
            <div className='container'>
                <div className='text_noticias_list'>
                    <h2>Administrador de noticias</h2>
                    <p>Escribe y publica noticias para mantener a tu comunidad <br /> al día con los acontecimientos más importantes.</p>                    
                    <button className='new' onClick={() => handleNew()}><i class="fi fi-rr-edit-alt"></i> Nueva noticia</button>  
                </div>
            </div>
        </section>

        <section className='table-noticias'>
            <div className='container'>
                <table className='table table-striped'>
                    <thead className='thead-light'>
                    <tr>
                        <th>Foto header</th>
                        <th>Título</th>
                        <th>Descripción</th>         
                        <th>Categoria</th>
                        <th>Fecha creación</th>           
                        <th>Visible</th>
                        <th>Editar</th>
                        <th>Eliminar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {noticias.map((task) => (

                        <tr key={task.id}>  
                                      
                        <td><img src={'../images/noticias/'+task.photo_route_header} alt='noticia' /></td>
                        <td lassName='title'> {task.title}</td>
                        <td className='descrption'>{task.description}</td>                        
                        <td>{task.category.join(', ')}</td>
                        <td>{task.creation_date}</td>
                        <td>{task.visible ? "Si" : "No"}</td>
                        <td> 
                            <button className='editar'  onClick={() => navigate(`/noticias/${task.id}/edit`) }> <i class="fi fi-rr-pencil"></i> Editar</button>
                        </td>
                        <td>
                            <button className='eliminar' onClick={() => handleDelete(task.id)}><i class="fi fi-rs-eraser"></i> Eliminar</button>
                        </td>

                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </section>
        
        
        {/*
    
        <table>
            <thead>
            <tr>
                <th>Foto header</th>
                <th>Título</th>
                <th>Descripción</th>
                <th>Foto footer</th>
                <th>category</th>
                <th>Fecha creación</th>
                <th>Modificatión</th>
                <th>Usuario</th>
                <th>Hora</th>
                <th>Visible</th>
                <th>Editar</th>
                <th>Eliminar</th>
            </tr>
            </thead>
            <tbody>
            {noticias.map((task) => (

                <tr key={task.id}>                
                <td>{task.photo_route_header}</td>
                <td>{task.title}</td>
                <td>{task.description}</td>
                <td>{task.photo_route_footer}</td>
                <td>{task.category.join(', ')}</td>
                <td>{task.creation_date}</td>
                <td>{task.modification_date}</td>
                <td>{task.user_manager}</td>
                <td>{task.creation_time}</td>
                <td>{task.visible}</td>
                <td>
                    <button onClick={() => navigate(`/noticias/${task.id}/edit`) }>Editar</button>
                </td>
                <td>
                    <button onClick={() => handleDelete(task.id)}>Eliminar</button>
                </td>

                </tr>
            ))}
            </tbody>
        </table>
        
        */}
        
        </>
        
    )
}

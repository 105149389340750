import React from 'react'
import '../css/footer_carga.css'
import logo from '../assets/images/LOGO-BF-BLANCO.png'
import facebook from '../assets/images/spotify/facebook.svg';
import twitter from '../assets/images/spotify/twitter.svg';
import instagram from '../assets/images/spotify/instagram.svg';
import youtube from '../assets/images/spotify/youtube.svg';
import spotify_logo from '../assets/images/spotify/spotify.svg';
import { Outlet, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function FooterCarga() {
    const { t } = useTranslation('footer_carga');
    
  return (
    <>
    <section class="footer_carga">

       <div className='container'>
            <div className='footer_content_carga'>
                <div className='footer_main_box_carga'>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <img src={logo} alt='logo baja ferries blanco' />
                    </div>  
                    <p>{t('text1')} </p>                
                    <div className='box_socials_links carga'>
                        <div className='box_link_social'>
                            <a href="https://www.facebook.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={facebook} alt='facebook oficial bajaferries' className='bg_spotify' /> </a>                                                                            
                        </div>
                        <div className='box_link_social'>                                        
                            <a href="https://x.com/bajaferries" rel='noopener noreferrer' target="_blank"><img src={twitter} alt='twitter oficial bajaferries' className='bg_spotify' /> </a>                                      
                        </div>
                        <div className='box_link_social'>
                            <a href="https://www.instagram.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={instagram} alt='instagram oficial bajaferries' className='bg_spotify' /> </a>                                                                                
                        </div>
                        <div className='box_link_social'>
                            <a href="https://www.youtube.com/@bajaferries2914" rel='noopener noreferrer' target="_blank"><img src={youtube} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                        </div>  
                        <div className='box_link_social'>
                            <a href="https://open.spotify.com/user/in0rh2go0af0k6asr46bzxun7" rel='noopener noreferrer' target="_blank"><img src={spotify_logo} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                                                                                                
                        </div>                            
                    </div>
                </div>
                <div className='footer_container_carga'>
                    <div className='footer_box_carga'>                        
                        <ul>
                            <li><h3>{t('text2')} </h3></li>
                            <li><Link to="/soluciones-logisticas">{t('text3')}</Link></li>
                            <li><Link to="/maritimo">{t('text4')}</Link></li>
                            <li><Link to="/terrestre">{t('text5')}</Link></li>
                            <li><Link to="/multimodal">{t('text6')}</Link></li>
                            <li><Link to="/integracion-logistica">{t('text7')}</Link></li>                            
                        </ul>
                    </div>
                    <div className='footer_box_carga'>                    
                        <ul>
                            <li><h3>{t('text8')}</h3></li>
                            <li><Link to="/nosotros">{t('text9')}</Link></li>
                            <li><Link to="/rutas-horario-carga">{t('text10')}</Link></li>
                            <li><Link to="/tarifas-carga">{t('text11')}</Link></li>
                            <li><Link to="/promociones">{t('text12')}</Link></li>
                            <li><Link to="/certificaciones">{t('text13')}</Link></li>
                        </ul>
                    </div>
                    <div className='footer_box_carga'>                    
                        <ul>
                            <li><h3>{t('text14')} </h3></li>                            
                            <li><Link to="/contacto">{t('text15')}</Link></li>
                            <li><Link to="/preguntas-frecuentes">{t('text16')} </Link></li>
                            <li><a className="navbar-link" href="http://proveedores.bajaferries.com/ProveedoresLogin.aspx" target="_blank" rel="noopener noreferrer">{t('text17')}</a> </li>                          
                            <li><Link to="/condiciones-de-servicio">{t('text18')} </Link></li>
                            <li><Link to="/aviso-de-privacidad">{t('text19')} </Link></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            
       </div>
       <div className='footer_copyright_carga'>
            <div className='container'>
                <div>
                    <label>Copyright © BAJA FERRIES S.A.P.I. DE C.V. 2024. </label>
                    <label> {t('text20')}</label>
                </div>
             
            </div>
        </div>

    </section>
    <Outlet />
    </>
  )
}

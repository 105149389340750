import React, { useState, useEffect } from 'react'
import '../css/tarifario.css'

import { Outlet, Link } from "react-router-dom";
import Booking from '../components/form/Booking';
import Footer from '../components/Footer';
import reservacion from '../assets/images/cta/reservacion.svg'
import promociones from '../assets/images/cta/promociones.svg'
import rutas from '../assets/images/cta/rutasyhorarios.svg'
import { useTranslation } from 'react-i18next';

export default function Tarifas() {
    const [selectElegirRuta, setSelectElegirRuta] = useState(false);
    const [nameOption, setNameOption] = useState('La Paz - Topolobampo');
    const { t } = useTranslation('tarifas');

    const chooseRutasVisible = () => {
        setSelectElegirRuta(!selectElegirRuta);
    };
    const rutaSelected = (option) => {
        setNameOption(option);            
        setSelectElegirRuta(!selectElegirRuta);
    };


  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('tarifas')}</h3>
                    <p> {t('tarifas_p')}</p>
                </div>                
            </div>
        </div>
    </section>
    
 
    <section className='tarifario'>
        
    <div>
    <Booking />
    </div>
                    
        <div className='select_tarifario'>
            <div className='container'>
            <div className="rutas_horario_tabs">
                    <div className='select_route_ruta_horario'>           
                        <label>{t('ruta')}</label>
                        <div className='box_select_route' onClick={chooseRutasVisible} >                    
                            <div className='select_ruta__horario'>                               
                                    <label>{nameOption} </label>
                                    <i class="fi fi-br-angle-small-down"></i>
                                </div>                                                      
                            <div>
                                {selectElegirRuta && 
                                <div className='optionListRoutes'>
                                    <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                    <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Mazatlan')}>La Paz - Mazatlán</div>   
                                    <div className='optionRoutes' onClick={() => rutaSelected('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                    <div className='optionRoutes' onClick={() => rutaSelected('Mazatlan - La Paz')}>Mazatlán - La Paz</div>
                                </div>
                                }
                            </div>
                        </div>                              
                    </div>                                                    
                </div>

                {/*<div>           
                    <div className='box_select_ruta_horario' onClick={chooseRutasVisible} >
                        <div style={{width: 'fit-content'}}  >                        
                            <div className='select_ruta'>
                                <label>Selecciona una ruta</label>
                                <label>{nameOption} <i class="fi fi-br-angle-small-down"></i></label>
                            </div>
                        </div>    
                        <div>
                            {selectElegirRuta && 
                            <div className='optionListRoutes'>
                                <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                                <div className='optionRoutes' onClick={() => rutaSelected('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                <div className='optionRoutes' onClick={() => rutaSelected('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                            </div>
                            }
                        </div>
                    </div>      
                </div>*/}
            </div>
        </div>
        <div className='container'>
        {nameOption === 'La Paz - Topolobampo' || nameOption === 'Topolobampo - La Paz' ? (
            <>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                        <tr>
                            <th>{t('pasajero')}</th>
                            <th>{t('tarifa')}</th>
                        </tr>
                        <tr>
                            <td> {t('adulto')}<br /> <p>{t('texto1')}</p></td>
                            <td>$1,950</td>
                        </tr>
                        <tr>
                            <td>{t('infante')} <br /> <p>{t('texto2')}</p></td>
                            <td>{t('texto5')}</td>
                        </tr>
                        <tr>
                            <td> {t('ninos')}<br /> <p>{t('texto3')}</p></td>
                            <td>$975</td>
                        </tr>
                        <tr>
                            <td>{t('adulto_mayor')} <br /> <p>{t('texto4')}</p></td>
                            <td>$975</td>
                        </tr>
                        </tbody>
                    </table>            
                </div>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>{t('vehiculo')}</th>
                                <th>{t('tarifa')}</th>
                            </tr>
                            <tr>
                                <td>{t('texto22')} <br /> </td>
                                <td>$430</td>
                            </tr>
                            <tr>
                                <td>{t('texto6')} <br /> <p>{t('texto7')}.</p></td>
                                <td>$3,800</td>
                            </tr>
                            <tr>
                                <td>{t('texto8')} <br /> <p>{t('texto9')}.</p></td>
                                <td>$5,650</td>
                            </tr>
                            <tr>
                                <td>{t('texto10')} <br /> <p>{t('texto11')}.</p></td>
                                <td>$12,950</td>
                            </tr>
                            <tr>
                                <td>{t('texto12')} <br /> <p>{t('texto13')}.</p></td>
                                <td>$12,950</td>
                            </tr>
                            <tr>
                                <td>{t('texto14')} <br /> <p>{t('texto15')}.</p></td>
                                <td>$17,120</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                               
                                <th>{t('cabinas')}</th>
                                <th>{t('tarifa')}</th>
                            </tr>
                            <tr>
                            
                                <td> <p>{t('texto16')}</p><p> {t('texto17')}.</p></td>
                                
                                <td>$2,400</td>
                            </tr>
                                                                        
                        </tbody>
                    </table>           
                </div>
                <div className='tabla-tarifa'>
                    
                    <div className='tabla_grid img'>
                        <div className=''>
                            {/*<label>Explora nuestra sección de carga donde encontraras las tarifas y un formulario de contacto</label>*/}
                            <div className='tabla_grid_button'>
                                <Link to="/tarifas-carga"> {t('texto18')}<i class="fi fi-rr-arrow-small-right"></i> </Link> 
                            </div>                                    
                        </div>                        
                    </div>                                    
                   
                </div>
            </div>
            </>
        ): (
            <>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                        <tr>
                            <th>{t('pasajero')}</th>
                            <th>{t('tarifa')}</th>
                        </tr>
                        <tr>
                            <td>{t('adulto')} <br /> <p>{t('texto1')}</p></td>
                            <td>$1,950</td>
                        </tr>
                        <tr>
                            <td>{t('infante')} <br /> <p>{t('texto2')}</p></td>
                            <td>Sin costo</td>
                        </tr>
                        <tr>
                            <td>{t('ninos')} <br /> <p>{t('texto3')}</p></td>
                            <td>$925</td>
                        </tr>
                        <tr>
                            <td>{t('adulto_mayor')} <br /> <p>{t('texto4')}</p></td>
                            <td>$925</td>
                        </tr>
                        </tbody>
                    </table>            
                </div>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>{t('vehiculo')}</th>
                                <th>{t('tarifa')}</th>
                            </tr>
                            <tr>
                                <td>{t('texto22')} <br /> </td>
                                <td>$430</td>
                            </tr>
                            <tr>
                                <td>{t('texto6')} <br /> <p>{t('texto7')}.</p></td>
                                <td>$4,650</td>
                            </tr>
                            <tr>
                                <td>{t('texto8')} <br /> <p>{t('texto9')}.</p></td>
                                <td>$6,500</td>
                            </tr>
                            <tr>
                                <td>{t('texto10')} <br /> <p>{t('texto11')}.</p></td>
                                <td>$18,150</td>
                            </tr>
                            <tr>
                                <td>{t('texto12')} <br /> <p>{t('texto13')}.</p></td>
                                <td>$18,150</td>
                            </tr>
                            <tr>
                                <td>{t('texto14')} <br /> <p>{t('texto15')}.</p></td>
                                <td>$26,100</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>{t('cabinas')}</th>
                                <th>{t('tarifa')}</th>
                            </tr>
                            <tr>
                                <td> <br /> <p>{t('texto19')}</p><p>{t('texto20')}</p></td>
                                <td>$2,400</td>
                            </tr>
                            <tr>
                                <td><br /> <p>{t('texto21')} </p><p>{t('texto20')}</p></td>
                                <td>$2,400</td>
                            </tr>                                               
                        </tbody>
                    </table>           
                </div>
                <div className='tabla-tarifa'>
                    
                    <div className='tabla_grid img'>
                        <div className=''>
                            {/*<label>Explora nuestra sección de carga donde encontraras las tarifas y un formulario de contacto</label>*/}
                            <div className='tabla_grid_button'>
                                <Link to="/tarifas-carga">{t('texto18')} <i class="fi fi-rr-arrow-small-right"></i> </Link> 
                            </div>                                    
                        </div>                        
                    </div>                                                       
                </div>
            </div>
            </>
        )}
        </div>
    </section>

    <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title1')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title1')}</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/promociones">{t('cta_btn1')}</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={rutas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title2')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title2')}</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/rutas_horario">{t('cta_btn2')}</Link>     
                            </div>
                        </div>
                    </div>
                    {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Envianos un WhatsApp</h4>
                                </div>
                                <div>
                                    <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title3')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">{t('cta_btn3')}</a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
    </section>

    <Outlet />
    <Footer />
    </>
  )
}


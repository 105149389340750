import React, {  useState } from 'react';
import '../css/durante_viaje.css'

import { Outlet } from "react-router-dom";

import Footer from '../components/Footer';

import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/pregutas.png'
import { useTranslation } from 'react-i18next';

const Tab = ({ label, onClick, active, icon }) => {
    return (
      <div className={`tab_viajar ${active ? 'active' : ''}`} onClick={onClick}>       
        <p>{label}</p>
      </div>
    );
};

export default function Despues_viaje() {
    const { t } = useTranslation('al_llegar');
    
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    
  return (
    <>
    <section id="hero-section">
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('al_llegar')}</h3>
                    <p>{t('al_llegar_p')}</p>
                </div>                
            </div>
        </div>                                 
    </section>
  
   <sections className='info_durante_viajar'>
   <div className="container">
        <div className='tabs_viajar'>
           <div className='tab-container'>
            <div className="tab-boxes">
                    <Tab
                    label={t('text1')}
                    onClick={() => handleTabClick(0)}
                    active={activeTab === 0}
                    
                    />
                    <Tab
                    label={t('text4')}
                    onClick={() => handleTabClick(1)}
                    active={activeTab === 1}
                    
                    />
                    <Tab
                    label={t('text7')}
                    onClick={() => handleTabClick(2)}
                    active={activeTab === 2}
                    
                    />
                    <Tab
                    label={t('text10')}
                    onClick={() => handleTabClick(3)}
                    active={activeTab === 3}
                    
                    />
                
                </div>
           </div>
            <div className="content-container">
            
                {activeTab === 0 && 
                <div className='info_antes_viaje'>
                    <h4>{t('text1')}</h4>
                    <p>{t('text2')}</p>                    
                    <p>{t('text3')}</p>
                    
                </div>}
                {activeTab === 1 && 
                <div className='info_antes_viaje'>
                    <h4>{t('text4')} </h4>
                    <p>{t('text5')}</p>
                    <p>{t('text6')} </p>
                    
                </div>}
                {activeTab === 2 && 
                <div className='info_antes_viaje'>                           
                        <h4>{t('text7')} </h4>
                        <p>{t('text8')} </p>
                        <p>{t('text9')}</p>
                    </div>}
                {activeTab === 3 && 
                    <div className='info_antes_viaje'>
                        <h4>{t('text10')} </h4>
                        <p>{t('text11')}</p>
                        <p>{t('text12')}</p>
                        
                    </div>}                                         

            </div>
        </div>
    </div>
   </sections>



   <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Comparte tu opinión</h4>
                                </div>
                                <div>
                                    <p>Tus comentarios son muy importantes para continuar ofreciondote el mejor servicio.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>                                                                
                                <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es" target="_blank" rel="noopener noreferrer">Más información </a> 
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Necesitas hacer cambios en tu reservación?</h4>
                                </div>
                                <div>
                                    <p>Envíanos un WhatsApp. Nuestro equipo está listo para ayudarte. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>                                
                                <a href="https://wa.me/5218003377437?text=Necesito%20cambiar%20una%20mi%20reservacón" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Siguenos en redes sociales</h4>
                                </div>
                                <div>
                                    <p>Mantente al día con nuestras últimas noticias, promociones y actualizaciones. ¡Únete a nuestra comunidad online!</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <a href="https://www.facebook.com/bajaferriesoficial/" target="_blank" rel="noopener noreferrer">Ir ahora </a>                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <Outlet />
    <Footer />
    
    </>
  )
}

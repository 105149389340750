// QuestionDetails.js

import React, {useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import '../../../css/answer.css'
import bookingIcon from '../../../assets/images/iconsPreguntas/calendario1.png'
import reservar  from "../../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import carga from '../../../assets/images/iconsPreguntas/barco-de-carga.png'
import avisos from '../../../assets/images//iconsPreguntas/megafono.png'
import pagos_facturas from '../../../assets/images/iconsPreguntas/factura.png'
import ShareButton from '../../ShareButton';
import reservacion from '../../../assets/images/cta/reservacion.svg'
import formulario from '../../../assets/images/formulario-de-contacto.svg'
import mapa from '../../../assets/images/mapa.svg'
import Footer from '../../Footer';
import { useTranslation } from 'react-i18next';


function DetailAntesDeViajar() {
    const { t } = useTranslation('respuestaAntesViajar');
    const [modal, setModal] = useState(false);
  const { id } = useParams();

  const questions = [
    {   id: 1,
        text: t('pregunta1'),
        answer: (
            <ul>
                <li>{t('respuesta1')}</li>
                <li>{t('respuesta2')}</li>
                <li>{t('respuesta3')}</li>
            </ul>
        ),
    },
    {
        id: 2,
        text: t('pregunta2'),
        answer: (
            <>
            <p>{t('respuesta4')}</p>
            <ul>
                <li>{t('respuesta5')}</li>
                <li>{t('respuesta6')}</li>
            </ul>
            <p>{t('respuesta7')}</p>
            <p>{t('respuesta8')}</p>
            </>
            
        )
    },
    {
        id: 3,
        text: t('pregunta3'),
        answer: t('respuesta9'),
    },
    {
        id: 4,
        text: t('pregunta4'),
        answer: t('respuesta10'),
    },
    {
        id: 5,
        text: t('pregunta5'),
        answer: t('respuesta11'),
    },
    {
        id: 6,
        text: t('pregunta6'),
        answer: t('respuesta12'),
    },
    {
        id: 7,
        text: t('pregunta7'),
        answer: t('respuesta13'),
    },
    
    {
        id: 8,
        text: t('pregunta8'),
         answer: t('respuesta14'),
    },
    {
        id: 9,
        text: t('pregunta9'),
        answer: t('respuest15'),
    },
    {
        id: 10,
        text: t('pregunta10'),
        answer: t('respuest16'),
    },
    {
        id: 11,
        text: t('pregunta11'),
        answer: t('respuest17'),
    },
    {
        id: 12,
        text: t('pregunta12'),
        answer: t('respuesta18'),
    },
    {
        id: 13,
        text: t('pregunta13'),
        answer: (
            <ul>
                <li>{t('pregunta13')} </li>
                <li>{t('pregunta13')} </li>
                <li>{t('pregunta13')}</li>
                <li>{t('pregunta13')}</li>              
            </ul>
        )
    }
];


  const question = questions.find(q => q.id === parseInt(id));

  if (!question) {
    return <div>Pregunta no encontrada</div>;
  }
  
  const shareUrl = `http://localhost:3000/preguntas-frecuentes-antes-viajar/${id}`;
  const title = 'Preguntas frecuentes';

  const showBoxShare = () => {
        setModal(!modal)
    }

  return (

    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('preguntas_frecuentes')}</h3>
                    <p>{t('preguntas_frecuentes_p')}</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='container'>
        <div className='opc_regresar'>
            <Link to="/antes-de-viajar-faqs"><i class="fi fi-rr-undo-alt"></i> {t('regresar')}</Link>  
        </div>
        <hr />
        <div className='box_titulo_faqs '>
            <div className='lista_preguntas'>
                <div className='answer_pgunas_frcuentes'>
                    <h1>{question.text}</h1>
                    <p> {question.answer}</p>
                </div>
            </div>
            <div className='lista_temas'>
                <h3>Otros temas frecuentes</h3>
                <div className='box_lista_temas'>
                    <ul>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={bookingIcon} alt='antesViajar' />
                                <Link to="/antes-de-viajar-faqs">Antes de viajar</Link>
                            </div>
                        </li>
                        <li> 
                            <div className='content_lista_temas'>
                                <img src={reservar} alt='antesViajar' />
                                <Link to="/reserva">Reservar</Link> 
                            </div>
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={pagos_facturas} alt='antesViajar' />
                                <Link to="/pagos-facturas">Pagos y facturas</Link> 
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={carga} alt='antesViajar' />
                                <Link to="/carga">Carga</Link>  
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={avisos} alt='antesViajar' />
                                <Link to="/automovil">Automóvil</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div className='container'>
            <div className='box_share'>
                <div><p>¿Fue útil esta información?</p></div>     
                <div>
                    <div className='btn_share' onClick={showBoxShare}>Compartir <i class="fi fi-rr-share"></i></div>
                    {modal && (
                        <div className='box_share_options'>
                            <ShareButton url={shareUrl} title={title} />
                        </div>
                    )}
                </div>
                
            </div>
        </div>        
    </section>

    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Contactar</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Ver más</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </section>
    <Footer />
    
    </>
  );
}



export default DetailAntesDeViajar;

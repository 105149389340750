import React, { useState, useRef, useEffect  } from 'react'
import '../css/integracionLogistica.css'
import Navbar from '../components/Navbar';
import contractLogistics from '../assets/images/soluciones/integracion/contract-logistics.png'
import freight_forwarding from '../assets/images/carga/terrestre/freight-forwarding.webp'
import { Outlet } from "react-router-dom";
import FooterCarga from '../components/FooterCarga'
import video from '../assets/video/integracionLogistica.mp4';
import solucioneslogisitcas from '../assets/images/soluciones/video-solucioneslogisitcas.png'
import { useTranslation } from 'react-i18next';

export default function IntegracionLogistica() {
    const { t } = useTranslation('integracionLogistica');
    const [isPlaying, setIsPlaying] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const videoRef = useRef(null);

    const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (isButtonVisible) {
            const timer = setTimeout(() => {
                setIsButtonVisible(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [isButtonVisible]);

 

  return (
    <>
    <Navbar />
   
    <section className='header_carga integracion'>
        <div className='container'>
            <div className='servicio_carga_header'>
                <div className='servicio_carga_box'>
                    <h2>{t('integracion')}</h2>
                    <p>{t('integracion_p')}</p>
                </div>
            </div>
        </div>
    </section>

    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box chartering text'>
                    <p></p>
                    <p>{t('text1')}</p>                 
                    <div className='btn_servicio_transporte'>
                        <a href="mailto:norma.munguia@baajferries.com.mx" rel="noopener noreferrer">{t('text6')}</a>                                              
                    </div>
                </div>
                <div className='servicio_carga_box ilvideo img'>
                    <video ref={videoRef} loop width="100%" height="500" poster={solucioneslogisitcas}>
                        <source src={video} type="video/mp4" />
                    </video>
                    <div className='btn-controls-video'>
                        <button className={`play-pause-button ${isButtonVisible ? 'button-visible' : 'button-hidden'}`} onClick={handlePlayPause}>
                            {isPlaying ? (<i class="fi fi-ss-pause"></i>):(<i class="fi fi-ss-play"></i>)}
                        </button>
                    </div>                                      
                </div>
            </div>
        </div>
    </section>    
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'> 
                <div className='servicio_carga_box img'>
                    <img src={contractLogistics} alt='servicio terrestre baja ferries' />
                </div>               
                <div className='servicio_carga_box feedering text'>
                    <h2>{t('text2')}  </h2>
                    <p>{t('text3')}</p>
                    <div className='btn_servicio_transporte'>                        
                    <a href="mailto:norma.munguia@bajaferries.com.mx" rel="noopener noreferrer">{t('text6')}</a>                                                              
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box proyecto text'>
                    <h2>{t('text4')}</h2>
                    <p>{t('text5')}</p>
                    <div className='btn_servicio_transporte'>                        
                    <a href="mailto:norma.munguia@bajaferries.com.mx" rel="noopener noreferrer">{t('text6')}</a>                                                             
                    </div>
                </div>
                <div className='servicio_carga_box img'>
                    <img src={freight_forwarding} alt='servicio terrestre baja ferries' />
                </div>
            </div>
        </div>
    </section>

    <FooterCarga />
    <Outlet />
    
    </>
  )
}

import React from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../css/destino.css'
import ImageSlider from '../sliders/SliderDestinos';
import promociones from '../../assets/images/cta/promociones.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/pregutas.png'
import Footer from '../Footer'
import {img1Lapaz, img2Lapaz, img3Lapaz} from '../../assets/base64'
import { useTranslation } from 'react-i18next';

export default function Lapaz() {
    const { t } = useTranslation('LaPaz');

    const images = [

        {
            image: img1Lapaz,
            title: 'Catedral de Nuestra Señora de La Paz',
            text: 'La capital del estado de Baja California Sur también conocida como la puerta al Mar de Cortés, es el lugar perfecto para disfrutar de unas relajadas y divertidas vacaciones. ',
            subtext: 'La Paz, Baja California Sur',
        },
        {
            image: img2Lapaz,
            title: 'Playa Balandra',
            text: 'Si te gusta la aventura y estar en el mar, puedes visitar una de las más famosas playas que ofrece la ciudad. Balandra, reconocida por sus cristalinas aguas y la formación rocosa en forma de hongo, se ha convertido en uno de los símbolos más representativos de la ciudad.',
            subtext: 'La Paz, Baja California Sur',
        },
        {
            image: img3Lapaz,
            title: 'Isla Espíritu Santo',
            text: 'Este increíble lugar, que fue nombrado por la UNESCO como Patrimonio de la Humanidad, alberga cerca del 40% de las especies de mamíferos marinos que existen en el mundo. ',
            subtext: 'La Paz, Baja California Sur ',
        },
        // Agrega más imágenes según sea necesario
      ];
    
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>La Paz, Baja California Sur.</h3>
                        <p>{t('laPaz')}</p>
                    </div>                
                </div>
            </div>
        </section>

        <section>
            <ImageSlider images={images}/>
        </section>

        <section className='destino'>            
            <div className='container'>
                <div className='destino_info_util'>
            
                    <div className='content_destinos_info'>
                        <div className='box_destinos_info'>
                            <h3>{t('text1')}</h3>
                            <ul>
                                <li><strong>{t('text2')}: </strong> {t('text3')}</li>
                                <li><strong>{t('text4')}: </strong> {t('text5')}</li>                                
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>{t('text6')}</h3>
                            <ul>
                                <li><strong>{t('text7')}: </strong> {t('text8')}</li>
                                <li><strong>{t('text9')}: </strong> {t('text10')}</li>
                                <li><strong>{t('text11')}: </strong> {t('text12')}</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>{t('text13')}</h3>
                            <ul>
                                <li><strong>{t('text15')}: </strong>{t('text16')}</li>
                                <li><strong>{t('text17')}: </strong>{t('text18')}</li>                        
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>{t('text19')}</h3>
                            <label>{t('text20')}:</label>
                            <ul>
                                <li><strong>{t('text21')}: </strong>{t('text22')}</li>
                                <li><strong>{t('text23')}: </strong>{t('text24')}</li>
                                <li><strong>{t('text25')}: </strong>{t('text26')}</li>
                                <li><strong>{t('text27')}: </strong>{t('text28')}</li>
                            </ul>
                        </div>    
                        <div className='box_destinos_info'>
                            <h3>{t('text29')}</h3>
                            <label>{t('text30')}:</label>
                            <ul>                            
                                <li><strong>{t('text31')}: </strong>{t('text32')}</li>                                                   
                            </ul>
                            <label>{t('text33')}:</label>
                            <ul>
                                <li><strong>{t('text34')}: </strong>{t('text35')}</li>
                            </ul>
                            <label>{t('text36')}:</label>
                            <ul>

                                <li><strong>{t('text37')}: </strong>{t('text38')}</li>                                                
                            </ul>
                        </div>                

                    </div>
                    <div className='content_destinos_info'>
                        
                        <div className='box_destinos_info'>
                            <h3>{t('text39')}</h3>
                            <label>{t('text40')}:</label>
                            <ul>
                                {/*
                                <li><strong>Los Cabos: </strong>Famoso por sus playas, actividades acuáticas y resorts de lujo.</li>
                                <li><strong>La Paz: </strong>Conocida por su malecón, playas cercanas como Balandra y su ambiente relajado.</li>
                                <li><strong>Loreto: </strong>Hogar del Parque Nacional Bahía de Loreto, ideal para el ecoturismo y la observación de ballenas.</li>
                                <li><strong>Cabo Pulmo: </strong>Parque Nacional Marino famoso por su arrecife de coral y buceo.</li>
                                <li><strong>Isla Espíritu Santo: </strong>Destino popular para el snorkeling, kayak y observación de la vida silvestre.</li>
                                    */}
                                <li><strong>{t('text41')}: </strong>{t('text42')}</li>
                                <li><strong>{t('text43')}: </strong>{t('text44')}</li>
                                <li><strong>{t('text45')}: </strong>{t('text46')}</li>
                                <li><strong>{t('text47')}: </strong> {t('text48')}</li>
                                <li><strong>{t('text49')}: </strong>{t('text50')}</li>
                            </ul>
                            
                        </div>
                       
                        <div className='box_destinos_info'>
                            <h3>{t('text51')}</h3>
                            <label>{t('text52')}:</label>
                            <ul>
                                <li><strong>{t('text53')}: </strong>{t('text54')}</li>
                                <li><strong>{t('text55')}: </strong>{t('text56')}</li>
                                <li><strong>{t('text57')}: </strong>{t('text58')}</li>                       
                                <li><strong>{t('text59')}: </strong>{t('text60')}</li>        
                                <li><strong>{t('text61')}: </strong>{t('text62')}</li>       
                            </ul>
                            <label>{t('text63')}:</label>
                            <ul>
                                <li> {t('text64')}</li>                   
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Ver tarifas</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Promociones y Descuentos</h4>
                            </div>
                            <div>
                                <p>Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <Link to="/promociones">Ir ahora</Link>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />

        <Footer />
    
    
    </>
  )
}

import React from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../css/faqs.css'
import Footer from '../Footer'
import '../../css/accordion.css'

import bookingIcon from '../../assets/images/iconsPreguntas/calendario1.png'
import reservar  from "../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import carga from '../../assets/images/iconsPreguntas/barco-de-carga.png'
import avisos from '../../assets/images//iconsPreguntas/megafono.png'
import pagos_facturas from '../../assets/images/iconsPreguntas/factura.png'
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
import { useTranslation } from 'react-i18next';


const Documentacion = () => {
    const { t } = useTranslation('pf_documentacion');
    const questions = [
        {
            id: 1,
            text: t('pregunta1'),
            answer:( 
                <ul>
                    <li>{t('respuesta1')}</li>
                    <li>{t('respuesta2')}</li>
                    <li>{t('respuesta3')}</li>
                    <li>{t('respuesta4')}</li>
                    <li>{t('respuesta5')}</li>               
                </ul>
            ),
        },
        {
            id: 2,
            text: t('pregunta2'),
            answer: t('respuesta6'),
        },
        {
            id: 3,
            text: t('pregunta3'),
            answer: t('respuesta7'),
        },
        {
            id: 4,
            text: t('pregunta4'),
            answer: t('respuesta8'),
        },
        {
            id: 5,
            text: t('pregunta5'),
            answer: ( 
                <>
                <p>{t('respuesta9')}</p>
                <ul>
                    <li>{t('respuesta10')}</li>
                </ul>
                <p>{t('respuesta11')}</p>
                <p>{t('respuesta12')}</p>
                <p>{t('respuesta13')}</p>
                <ul>
                    <li>{t('respuesta14')}</li>                                   
                    <li>{t('respuesta15')}</li>
                    <li>{t('respuesta16')}</li>
                    <li>{t('respuesta17')}</li>
                </ul>
                <p>{t('respuesta18')}</p>
                
                </>
                
            ),
        },
     
    
    ];

  return (
    <> 
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                <h3>{t('preguntas_frecuentes')}</h3>
                <p>{t('preguntas_frecuentes_p')}</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='container'>
            <div className='box_titulo_faqs '>
                <div className='lista_preguntas'>
                    <h3>{t('documentacion')}</h3>                           
                    <ul>
                        {questions.map(question => (
                        <li key={question.id}>
                            <Link to={`/preguntas-frecuentes-documentacion/${question.id}`}>{question.text}</Link>
                        </li>
                        ))}
                    </ul>           
                </div>
            <div className='lista_temas'>
                <h3>Otros temas frecuentes</h3>
                <div className='box_lista_temas'>
                    <ul>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={bookingIcon} alt='antesViajar' />
                                <Link to="/antes-de-viajar-faqs">Antes de viajar</Link>
                            </div>
                        </li>
                        <li> 
                            <div className='content_lista_temas'>
                                <img src={reservar} alt='antesViajar' />
                                <Link to="/reserva">Reservar</Link> 
                            </div>
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={pagos_facturas} alt='antesViajar' />
                                <Link to="/pagos-facturas">Pagos y facturas</Link> 
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={carga} alt='antesViajar' />
                                <Link to="/carga">Carga</Link>  
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={avisos} alt='antesViajar' />
                                <Link to="/automovil">Automóvil</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Consultar</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Dinos que opinas</h4>
                            </div>
                            <div>
                                <p>Tú opinión para nosotros es muy importante para continuar ofreciondote el mejor servicio. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es   " target="_blank" rel="noopener noreferrer">Contactar</a>
                            
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                        
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </section>
    <Footer />
        
    </>
  )
}


export default Documentacion;
import React from 'react';
import '../css/timeline.css';
import { useTranslation } from 'react-i18next';
/* Imágenes */
import img01 from '../assets/images/timeline/2003.webp'
import img02 from '../assets/images/timeline/2005.png'
import img03 from '../assets/images/timeline/2006.png'
import img04 from '../assets/images/timeline/2008.png'
import img05 from '../assets/images/timeline/2013.png'
import img06 from '../assets/images/timeline/2015.png'
import img07 from '../assets/images/timeline/2016.png'
import img08 from '../assets/images/timeline/2017.png'
import img09 from '../assets/images/timeline/2018.webp'
import img10 from '../assets/images/timeline/2019.webp'
import img11 from '../assets/images/timeline/2020.png'
import img12 from '../assets/images/timeline/2021.webp'
import img13 from '../assets/images/timeline/2022.png'
import img14 from '../assets/images/timeline/2023.webp'

const TimelineEvent = ({ event, isLeft }) => {
    return (
        <div className={`timeline-event ${isLeft ? 'left' : 'right'}`}>
            <div className='timeline-content'>
                <div className='timeline-box'>
                    <div className='timeline-text'>
                        <div className="event-date"><h3>{event.date}</h3></div>
                        <div className="event-title"><p>{event.title}</p></div>
                    </div>
                    <div className="event-details">
                        <img src={event.image} alt={event.title} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const Timeline = ({ events }) => {
    return (
        <div className="timeline">
            <div className="line"></div>
            <div className="events-container">
                {events.map((event, index) => (
                    <TimelineEvent key={index} event={event} isLeft={index % 2 === 0} />
                ))}
            </div>
        </div>
    );
};

// Componente principal
function TimeLine() {
    const { t } = useTranslation('nosotros');

    // Datos de la línea de tiempo traducidos usando `t`
    const timelineEvents = [
        { date: "2003", title: t("2003"), image: img01 },
        { date: "2005", title: t("2005"), image: img02 },
        { date: "2006", title: t("2006"), image: img03 },
        { date: "2008", title: t("2008"), image: img04 },
        { date: "2013", title: t("2013"), image: img05 },
        { date: "2015", title: t("2015"), image: img06 },
        { date: "2016", title: t("2016"), image: img07 },
        { date: "2017", title: t("2017"), image: img08 },
        { date: "2018", title: t("2018"), image: img09 },
        { date: "2019", title: t("2019"), image: img10 },
        { date: "2020", title: t("2020"), image: img11 },
        { date: "2021", title: t("2021"), image: img12 },
        { date: "2022", title: t("2022"), image: img13 },
        { date: "2023", title: t("2023"), image: img14 },
    ];

    return (
        <div>
            <h2>{t("linea_tiempo_h2")}</h2> {/* Título traducido */}
            <Timeline events={timelineEvents} />
        </div>
    );
}

export default TimeLine;

import React from 'react'
import '../../css/faqs.css'
import { Outlet, Link } from "react-router-dom";

import Footer from '../Footer'
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'

import '../../css/accordion.css'

import reservar from '../../assets/images/iconsPreguntas/equipaje-de-viaje2.png'
import carga  from "../../assets/images/iconsPreguntas/barco-de-carga.png";
import documentacion from '../../assets/images/iconsPreguntas/documentacion.png'
import avisos from '../../assets/images//iconsPreguntas/megafono.png'
import pagos_facturas from '../../assets/images/iconsPreguntas/factura.png'
import { useTranslation } from 'react-i18next';

const AntesDeViajar = () => {
    const { t } = useTranslation('pf_antesViajar');
    const questions = [
        {   id: 1,
            text: t('texto1'),
            answer: (
                <ul>
                    <li>Los pasajeros menores de edad deben viajar con asistencia o compañía obligatoria de una persona mayor a 18 años.</li>
                    <li>Deben presentar alguna de las siguientes identificaciones oficiales: pasaporte nacional o internacional, acta de nacimiento o CURP para corroborar su identidad.</li>
                    <li>En caso de que el menor de edad no vaya acompañado por alguno de sus padres, el adulto a cargo deberá presentar una carta de consentimiento firmada junto con una identificación oficial del padre o madre donde estos autorizan que el menor pueda viajar. </li>
                </ul>
            ),
        },
        {
            id: 2,
            text: t('texto2'),
            answer: (
                <>
                <p>Te recomendamos abordar el buque con un equipaje ligero de mano con lo necesario para tu viaje que cumplan las siguientes condiciones:</p>
                <ul>
                    <li>El peso permitido por equipaje de mano es de 10 kg máximo</li>
                    <li>Puede medir hasta 57 x 40 x 33 cm (dimensión total).</li>
                </ul>
                <p>Para mayor seguridad y confort de los pasajeros, el equipaje de mano que sobre pase el peso o dimensiones mencionadas será documentado.</p>
                <p>Debes tomar las precauciones debidas para proteger e identificar tu equipaje, ya que la empresa no se hace responsable por la pérdida de objetos personales.</p>
                </>
                
            )
        },
        {
            id: 3,
            text: t('texto3'),
            answer: 'Identificación oficial vigente, expedida por el Instituto Nacional Electoral (INE), Pasaporte vigente, Cédula profesional vigente con fotografía, En el caso de menores de edad: acta de nacimiento, credencial emitida por Instituciones de Educación Pública o Privada con reconocimiento de validez oficial con fotografía y firma o pasaporte. Si eres extranjero: Pasaporte o documento migratorio vigente que corresponda, emitido por autoridad competente (en su caso, prórroga o refrendo migratorio)',
        },
        {
            id: 4,
            text: t('texto4'),
            answer: 'Para nosotros lo más importante es tu seguridad. Por lo tanto, una vez que inicia la travesía, ninguna persona deberá permanecer en las cubiertas de carga. Es necesario permanecer en las áreas de pasaje.',
        },
        {
            id: 5,
            text: t('texto5'),
            answer: 'La travesía en ruta Mazatlán dura aproximadamente 14 horas',
        },
        {
            id: 6,
            text: t('texto6'),
            answer: 'La travesía en ruta Topolobampo dura aproximadamente 8 horas',
        },
        {
            id: 7,
            text: t('texto7'),
            answer: 'Debe dirigirse a la ventanilla con su confirmación de viaje y con identificación oficial en mano, en caso de viajar con menores debe mostrar el acta de nacimiento original de cada uno, posterior a ello, imprimirán su boleto y procederán al embarque.  ',
        },
        
        {
            id: 8,
            text: t('texto8'),
            answer: 'El horario de salida dependerá del puerto y el día que busques viajar. Te invitamos a visitar nuestra seccion de Rutas y horarios para consultar tu posible itinerario.',
        },
        {
            id: 9,
            text: t('texto9'),
            answer: 'La duración de la travesía depende de la ruta en la que viajes. Hacia o desde el puerto de Mazatlán la travesía es de 14 horas y del puerto de Topolobampo 9 hrs aproximadamente. Es importante tomar en cuanta que el abordaje y el desembarque toman tiempo adicional a las horas de travesía. ',
        },
        {
            id: 10,
            text: t('texto10'),
            answer: 'No esta permitido abordar con alimentos o bebidas, pero no te peocupes tu reservación incluye una rica comida que sirven antes de zarpar y nuestros buques cuentan con venta de alimentos y snacks durante la travesía. Es importante tener en cuenta que solo se aceptan pagos en efectivo.             ',
        },
        {
            id: 11,
            text: t('texto11'),
            answer: 'Si tienes alguna condición que límite tu movilidad y deseas viajar, te pedimos comunicarte o enviar mensaje a nuestro Contact Center  donde una ejecutiva te brindará asesoría. ',
        },
        {
            id: 12,
            text: t('texto12'),
            answer: 'Si viajas con niños pequeños, puedes abordar con su carreola o porta bebé. Toma en cuanta que, por seguridad,  te recomendamos utilizar carreola en las áreas de pasajeros al interior del buque. ',
        },
        {
            id: 13,
            text: t('texto13'),
            answer: (
                <ul>
                    <li>De acuerdo a nuestra política, por seguridad e higiene de los pasajeros, no está permitido abordar con cobijas o almohadas, deberás documentarlas con el resto de tu equipaje. Te recomendamos viajar con una chamarra ligera en verano y una gruesa en invierno. 
                    <li>Los pasajeros que viajan en salón ejecutivo o que documentaron su cobija, pueden solicitar una frazada (bajo disponibilidad) en el área de recepción mostrando su brazalete azul o boleto con sello del área de maletas según sea el caso. </li>
                    <li>Si no llevo cabina ¿en dónde viajo?</li>
                    <li>Si no cuentas con reservación de cabina, puedes viajar en las áreas destinadas para pasajeros siguiendo las instrucciones de la tripulación.</li></li>
                </ul>
            )
        }       
    
    ];

  return (
    <> 

        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>{t('preguntas_frecuentes')} </h3>
                        <p>{t('preguntas_frecuentes_p')}</p>
                    </div>                
                </div>
            </div>
        </section>
 
        <section className='container'>
            <div className='box_titulo_faqs '>
                <div className='lista_preguntas'>
                    <h3>{t('antesViajar')}</h3>                           
                    <ul>
                        {questions.map(question => (
                        <li key={question.id}>
                            <Link to={`/preguntas-frecuentes-antes-viajar/${question.id}`}>{question.text}</Link>
                        </li>
                        ))}
                    </ul>           
                </div>
                <div className='lista_temas'>
                    <h3>Otros temas frecuentes</h3>
                    <div className='box_lista_temas'>
                        <ul>                                                                         
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={reservar} alt='antesViajar' />
                                    <Link to="/reserva">Reserva</Link>
                                </div>
                            </li>
                            <li> 
                                <div className='content_lista_temas'>
                                    <img src={documentacion} alt='antesViajar' />
                                    <Link to="/documentacion">Documentación</Link> 
                                </div>
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={pagos_facturas} alt='antesViajar' />
                                    <Link to="/pagos-facturas">Pagos y facturas </Link> 
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={carga} alt='antesViajar' />
                                    <Link to="/carga">Carga </Link>  
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={avisos} alt='antesViajar' />
                                    <Link to="/automovil">Automóvil</Link>
                                </div>
                            </li>
                        </ul>
                    </div>                
                </div>
            </div>
        </section>
        <Outlet />

        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Consultar</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Dinos que opinas</h4>
                            </div>
                            <div>
                                <p>Tú opinión para nosotros es muy importante para continuar ofreciondote el mejor servicio. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es   " target="_blank" rel="noopener noreferrer">Contactar</a>
                            
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                        
                        </div>
                    </div>
                </div>*/}
               
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </section>
        <Footer />
        
    </>
  )
}
export default AntesDeViajar;
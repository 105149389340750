import React from 'react'
import '../css/fleetservice.css';
import cabina from '../assets/images/flota/mex/Foto_Cabina.jpg'
import salon from '../assets/images/flota/mex/Foto_Salon.jpg'
import restaurante from '../assets/images/flota/mex/Foto_Restaurante.jpg'
import bar from '../assets/images/flota/mex/Foto_Bar.jpg'
import doctor from '../assets/images/cirujano.png'
import Footer from './Footer'
import { useTranslation } from 'react-i18next';

export default function Fleetservice_mex() {
    const { t } = useTranslation('servicioMexico');
  return (
    <>
    
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>México Star</h3>
                    <p>{t('header_section_title_p')}</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='fleetserices_ship'>   
        <section className='fleetserices'>
            <div className='container'>
                <div className='fleetserices_content'>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={cabina} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_cabinas')}</h2>
                                <p>{t('fleetserices_cabinas_text')}</p>
                                <p>{t('fleetserices_cabinas_text2')}</p>
                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={salon} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_salon')}</h2>
                                <p>{t('fleetserices_salon_text')}</p>
                                <p>{t('fleetserices_salon_text2')}</p>
                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={restaurante} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_restaurante')}</h2>
                                <p>{t('fleetserices_restaurante_text1')}</p>
                                <p>{t('fleetserices_restaurante_text2')}</p>
                                <p>{t('fleetserices_restaurante_text3')}</p>
                                <p>{t('fleetserices_restaurante_text4')}</p>
                                <p>{t('fleetserices_restaurante_text5')}</p>
                                <p>{t('fleetserices_restaurante_text6')}</p>
                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='fleetserices_bottom entretenimiento'>
            <div className='container'>
                <div className='fleetserices_content'>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={bar} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_bar')}</h2>
                                <p>{t('fleetserices_bar_text1')} </p>
                                <p>{t('fleetserices_bar_text2')}</p>

                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>
        <section className='fleetserices_medico'>
            <div className='container'>
                <div className='fleetserices_content medico'>
                    <div className='fleetserices_box'>
                    <div className='fleetserices_img_box'>
                            <div className='fleetserices_img_medico'>
                                <img src={doctor} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text_medico'>
                                <h2>{t('fleetserices_medico')}</h2>
                                <p>{t('fleetserices_medico_text')}</p>                                
                                <div className='fleetserices_medico_icons'>
                                    <p><i class="fi fi-rr-syringe"></i></p>
                                    <p><i class="fi fi-rr-stethoscope"></i></p>
                                    <p><i class="fi fi-rr-capsules"></i></p>                                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>
    </section>
    <Footer/>
    </>
  )
}

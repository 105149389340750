import React, { useRef, useState, useEffect } from 'react';
import heroImg from '../assets/images/hero.jpg'
import '../css/hero.css';
import '../App.css';
import { useTranslation } from 'react-i18next';
import heroVideo from '../assets/video/Hero.mp4';

import Reserva from './form/Reserva';

export default function Hero() {
   const { t } = useTranslation('hero');
    const videoRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <>
  
    <section>
        <div className='hero' >

        {!isMobile ? (
             
          <video ref={videoRef} muted loop autoPlay playsinline preload={false} width="750"  height="500">
            <source src={heroVideo} type="video/mp4" />
          </video>
      
        ) : (
          <div className='heroImg'>
            <img src={heroImg} alt='baja ferries hero' />
        </div>
        )}
       
           
            <div className='hero_text'>
                <div className='container '>
                    <div className='content_hero_text'>
                        <div className='box_hero_text'>
                            <label>{t('text1')} </label>
                            <label>{t('text2')} </label>
                        </div> 
                        <div className='box_hero_text'>
                            <label>{t('text3')}  </label>
                            <label style={{display: 'flex', justifyContent:'start'}}>{t('text4')} </label>
                        </div>          
                    </div>
                </div>                                              
            </div>
            <div className='boxBooking'>
                <div className='container'>                                      
                  <Reserva />                                                                                                                                                                               
                </div>
                                
            </div>           
        </div>
    </section>
    </>
  )
}




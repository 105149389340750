import React from 'react'
import '../css/prepararViaje.css'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';

import antes from '../assets/images/informacion/antes.jpg';
import durante from '../assets/images/informacion/durante.png';
import despues from '../assets/images/despues.webp';

import reservar from '../assets/images/cta/reservacion.svg'
import viajeGrupo from '../assets/images/cta/viajegrupo.svg'
import auto from '../assets/images/cta/viajaenauto.svg'


import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';

export default function PrepararViaje() {
    const { t } = useTranslation('preparaViaje');
  return (
    <>

        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>{t('preparaViaje')}</h3>
                        <p>{t('preparaViaje_p')} </p>
                    </div>                
                </div>
            </div>
        </section>

        <section className='container'>
            
            <div className='box_prepararViaje'>
                <h2>{t('text1')}</h2>
                {/*
                <p>Baja Ferries te conecta con increíbles destinos para disfrutar solo, en pareja, con tu familia o amigos. Puedes viajar con tu 
                    auto y explorar todo lo que México tiene para ofrecer.</p>   */}             
            </div>
            <Row>
                <Col span={8}>
                    <div className='content_documentacion'>
                        <Link to="/antes-de-viajar">                                         
                            <div className="cardPrepararViaje">
                                <img src={antes} alt="pichilingue" className='imgDestino' />                        
                                <div className="textPrepararViaje">
                                    <h4>{t('text2')}</h4> 
                                    <p>{t('text3')}</p> 
                                </div>
                            </div>
                        </Link> 
                
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_antesViajar'>
                        <Link to="/durante_tu_viaje"> 
                        
                            <div className="cardPrepararViaje">
                                <img src={durante} alt="mazatlan" className='imgDestino' />                        
                                <div className="textPrepararViaje">
                                    <h4>{t('text4')}</h4> 
                                    <p>{t('text5')}</p> 
                                </div>
                            </div>

                        </Link>                         
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_paraViajar'> 
                        <Link to="/al-llegar">  
                            <div className="cardPrepararViaje">
                                <img src={despues} alt="topolobampo" className='imgDestino' />                        
                                <div className="textPrepararViaje">
                                    <h4>{t('text6')}</h4> 
                                    <p>{t('text7')}</p> 
                                </div>
                            </div>
                        </Link>                         
                    </div>
                </Col>               
            </Row>
        </section>
        {
            /*
        <section className='container'>
            <div className='box_prepararViaje'>
                <h2>Servicio al cliente</h2>
                {/*<p>Baja Ferries te conecta con increíbles destinos para disfrutar solo, en pareja, con tu familia o amigos. Puedes viajar con tu auto y explorar todo lo que México tiene para ofrecer.</p
            </div>
            <Row>
                <Col span={8}>
                    <div className='content_documentacion'>
                    <Link to="/preguntas-frecuentes"> 
                        <div className="cardPrepararViaje">
                            <img src={preguntasfrecuentes} alt="pichilingue" className='imgDestino' />                        
                            <div className="textPrepararViaje">
                                <h4>Preguntas frecuentes</h4> 
                                <p>Si necesitas consultar algo y tu pregunta no aparece en la lista, no dudes en ponerte en contacto con nuestro equipo de atención al cliente.</p> 
                            </div>
                        </div>
                    </Link>                         
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_antesViajar'>
                    <Link to="/contacto"> 
                        <div className="cardPrepararViaje">
                            <img src={contactanos} alt="mazatlan" className='imgDestino' />                        
                            <div className="textPrepararViaje">
                                <h4>Contáctanos</h4> 
                                <p>Conocida como “La Perla del Pacífico” es de los destinos más visitados en México por turistas nacionales e internacionales.</p> 
                            </div>
                        </div>
                    </Link>                        
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_paraViajar'> 
                        <Link to="/condiciones-de-servicio"> 
                            <div className="cardPrepararViaje">
                                <img src={terminoscondiciones} alt="topolobampo" className='imgDestino' />                        
                                <div className="textPrepararViaje">
                                    <h4>Términos y condiciones</h4> 
                                    <p>Consulta los términos y condiciones de Baja Ferries.</p> 
                                </div>
                            </div>
                        </Link>                        
                    </div>
                </Col>
            </Row>
        </section>
        
           
        <section className='container'>
            <LogoCarousel logos={logos} />
        </section>
         */
        }
         <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={reservar} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title1')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title1')}</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>                               
                                
                                <a href="https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch/" target="_blank" rel="noopener noreferrer">{t('cta_btn1')} </a>   
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={viajeGrupo} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title2')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title2')}</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/viaje-en-grupo">{t('cta_btn2')}</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={auto} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title3')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title3')}</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <Link to="/antes-de-viajar">{t('cta_btn3')}</Link> 
                                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        

        <Footer />
        <Outlet />
    </>
  )
}

import React from 'react'
import '../css/valoracion.css'
import ValoracionForm from '../components/form/ValoracionForm'

export default function Valoracion() {
  return (
    <>
        <ValoracionForm />
    </>
  )
}

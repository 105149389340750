import React from 'react'
import Navbar from '../components/Navbar';
import '../css/multimodal.css'
import intermodal from '../assets/images/carga/multimodal/intermodal.webp'
import FooterCarga from '../components/FooterCarga'
import { Outlet, Link } from "react-router-dom";
import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import { useTranslation } from 'react-i18next';

export default function Multimodal() {
    const { t } = useTranslation('multimodal');
  return (
    <> 
    <Navbar />
   
    <section className='header_carga multimodal'>
        <div className='container'>
            <div className='servicio_carga_header'>
                <div className='servicio_carga_box'>
                    <h2>{t('multimodal')}</h2>
                    <p>{t('multimodal_p')}</p>
                </div>
            </div>
        </div>
    </section>
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'> 
            <div className='servicio_carga_box'>
                    <img src={intermodal} alt='servicio multimodal baja ferries' />
                </div>
                <div className='servicio_carga_box feedering'>
                    <h2>{t('text1')} </h2>
                    <p>{t('text2')}</p>
                    <div className='btn_servicio_transporte'>                        
                    <a href="mailto:contacto.comercial@bajaferries.com.mx" rel="noopener noreferrer">{t('text3')}</a>                                                          
                    </div>
                </div>
                
            </div>
        </div>
    </section>

    <section className="callToAction carga">
        <div className='container'>

            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Transporte de carga rodada</h4>
                            </div>
                            <div>
                                <p>Contamos con servicio  de ferry con frecuencia diaria entre los puertos de Mazatlán, Topolobampo y La Paz para llevar tu carga desde o hacia B.C.S.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas-horario-carga">Ver horario</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Soluciones terrestres</h4>
                            </div>
                            <div>
                                <p>Creamos soluciones eficaces para el transporte de tus mercancías por carretera.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/terrestre">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Tienes un proyecto especial?</h4>
                            </div>
                            <div>
                                <p>Si necesitas transportar mercancía de grandes dimensiones y peso, trabajamos contigo para gestionar tu proyecto especial.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Contactar </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>
    <FooterCarga />
    
    <Outlet />
    
    </>
  )
}

import React from 'react'
import Navbar from '../components/Navbar';
import '../css/terrestre.css'
import FooterCarga from '../components/FooterCarga';
import transporte_carretera from '../assets/images/carga/terrestre/transporte-carretera.webp'
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import { Outlet, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function Terrestre() {
    const { t } = useTranslation('terrestre');
  return (
    <> 
    <Navbar />

    <section className='header_carga terrestre'>
        <div className='container'>
            <div className='servicio_carga_header'>
                <div className='servicio_carga_box'>
                    <h2>{t('terrestre')}</h2>
                    <p>{t('terrestre_p')}</p>
                </div>
            </div>
        </div>
    </section>
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content' style={{marginTop:'4rem'}}>                
                <div className='servicio_carga_box'>
                    <h2>{t('text1')}</h2>
                    <p>{t('text2')}</p>
                    <div className='btn_servicio_transporte'>                        
                        <a href="https://www.transportadorageos.com.mx/" target="_blank" rel="noopener noreferrer">{t('text3')} <i class="fi fi-rs-arrow-up-right-from-square"></i></a>  
                        <a href="mailto:rodolfo.vela@tgeos.mx" rel="noopener noreferrer">{t('text4')}</a>                                                                                  
                    </div>
                </div>
                <div className='servicio_carga_box'>
                    <img src={transporte_carretera} alt='servicio terrestre baja ferries' />
                </div>
            </div>
        </div>
    </section>


   

    <section className="callToAction carga">
        <div className='container'>
            
            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title1')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title1')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas-horario-carga">{t('cta_btn1')}</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title2')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title2')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" rel="noopener noreferrer"><span >{t('cta_btn2')}</span></a>          
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title3')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <a href="tel:8003377437" rel="noopener noreferrer"><span >{t('cta_btn3')}</span></a>                           
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />
    {/*        
        <CargaForm />
    */}
    <FooterCarga />
    
    </>
  )
}

import React from 'react'
import Navbar from '../components/Navbar';
import '../css/maritimo.css'
import chartering from '../assets/images/soluciones/maritimo/chartering.webp'
import feedering from '../assets/images/soluciones/maritimo/feedering.webp'
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import carga_proyecto from '../assets/images/carga/maritima/CARGA-PROYECTO.webp'
import ferry_carga from '../assets/images/soluciones/maritimo/ferry_carga.jpg'
import agentenaviero from '../assets/images/soluciones/integracion/agentenaviero.png'
import FooterCarga from '../components/FooterCarga'
import { Outlet, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Maritimo() {
    const { t } = useTranslation('maritimo');
  return (
    <> 
    <Navbar />

    <section className='header_carga maritimo'>
        <div className='container'>
            <div className='servicio_carga_header'>
                <div className='servicio_carga_box'>
                    <h2>{t('maritimo')} </h2>
                    <p>{t('maritimo_p')}</p>
                </div>
            </div>
        </div>
    </section>
 
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box chartering text'>
                    <h2>{t('text1')}</h2>
                    <p>{t('text2')}</p>
                    <div className='btn_servicio_transporte'>                        
                        <Link to="/rutas-horario-carga">{t('btn_ver')}</Link>  
                        <Link to="/tarifas-carga">{t('tarifas')}</Link>    
                        <a href="mailto:contacto.comercial@bajaferries.com.mx" rel="noopener noreferrer">{t('contactar')}</a>  
                    </div>
                </div>
                <div className='servicio_carga_box img'>
                    <img src={ferry_carga} alt='servicio maritimo baja ferries' />
                </div>
            </div>
        </div>
    </section>
   
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'> 
            <div className='servicio_carga_box img'>
                    <img src={feedering} alt='servicio maritimo baja ferries' />
                </div>               
                <div className='servicio_carga_box feedering text'>
                    <h2>{t('text3')} </h2>
                    <p>{t('text4')}</p>
                    <div className='btn_servicio_transporte'>                        
                        <a href="mailto:cristobal.calderon@bajaferries.com.mx" rel="noopener noreferrer">{t('contactar')}</a>                                                       
                    </div>
                </div>
                
            </div>
        </div>
    </section>

    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box chartering text'>
                    <h2>{t('text5')}</h2>
                    <p>{t('text6')}</p>
                    <div className='btn_servicio_transporte'>                        
                        <a href="mailto:cristobal.calderon@bajaferries.com.mx" rel="noopener noreferrer">{t('contactar')}</a>  
                                                                                   
                    </div>
                </div>
                <div className='servicio_carga_box img'>
                    <img src={chartering} alt='servicio maritimo baja ferries' />
                </div>
            </div>
        </div>
    </section>


    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'> 
            <div className='servicio_carga_box img'>
                    <img src={agentenaviero} alt='servicio terrestre baja ferries' />
                </div>               
                <div className='servicio_carga_box feedering  text'>
                    <h2>{t('text7')} </h2>                   
                    <p>{t('text8')}</p>
                    <div className='btn_servicio_transporte'>                        
                     
                    <a href="mailto:alexis.godoy@bajaferries.com.mx" rel="noopener noreferrer">{t('contactar')}</a>                                              
                    </div>
                </div>
                
            </div>
        </div>
    </section>

    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content '>                
                <div className='servicio_carga_box proyecto text'>
                    <h2>{t('text9')}</h2>                   
                    <p>{t('text10')}</p>
                    <div className='btn_servicio_transporte'>                        
                        
                        <a href="mailto:cristobal.calderon@bajaferries.com.mx" rel="noopener noreferrer">{t('contactar')}</a>                                                           
                    </div>
                </div>
                <div className='servicio_carga_box img'>
                    <img src={carga_proyecto} alt='servicio maritimo baja ferries' />
                </div>
            </div>
        </div>
    </section>

    <section className="callToAction carga">
        <div className='container'>
           
            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title1')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title1')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="tel:8003377437" rel="noopener noreferrer"><span >{t('cta_btn1')} </span></a>    
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title2')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title2')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/multimodal">{t('cta_btn2')} </Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title3')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                        
                            <a href="tel:8003377437" rel="noopener noreferrer"><span >{t('cta_btn3')} </span></a>                  
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

    <FooterCarga />
    <Outlet />
    
    </>
  )
}

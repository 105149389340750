import React, { useState } from 'react';
import '../css/antes_viajar.css'
import { Outlet, Link } from "react-router-dom";
import Footer from '../components/Footer';
import promociones from '../assets/images/cta/promociones.svg'
import reservar from '../assets/images/cta/reservacion.svg'
import rutas from '../assets/images/cta/rutasyhorarios.svg'
import { useTranslation } from 'react-i18next';

const Tab = ({ label, onClick, active, icon }) => {
    return (
      <div className={`tab_viajar ${active ? 'active' : ''}`} onClick={onClick}> 
        <p>{label}</p>
      </div>
    );
};


export default function Antes_de_viajar() {
    const { t } = useTranslation('antesViajar');
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

   
  return (
    <>

    <section id="hero-section">
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('antesViajar')}</h3>
                    <p>{t('antesViajar_p')}</p>
                </div>
            </div>
        </div>
    </section>
 
   <sections className='info_antes_de_viajar'>
   <div className="container">
        <div className='tabs_viajar'>
            <div className="tab-container">
            <div className="tab-boxes">
            
                <Tab
                label={t('text1')}
                onClick={() => handleTabClick(0)}
                active={activeTab === 0}                
                />
                <Tab
                label={t('text33')}
                onClick={() => handleTabClick(1)}
                active={activeTab === 1}
                icon={<i class="fi fi-rs-file-invoice-dollar"></i>}
                />
                <Tab
                label={t('text42_titulo')}
                onClick={() => handleTabClick(2)}
                active={activeTab === 2}                
                />
                <Tab
                label={t('text50')}
                onClick={() => handleTabClick(3)}
                active={activeTab === 3}                
                />
                
                <Tab
                label={t('text55')}
                onClick={() => handleTabClick(4)}
                active={activeTab === 4}            
                />
                <Tab
                label={t('text85')}
                onClick={() => handleTabClick(5)}
                active={activeTab === 5}                
                />
                <Tab
                label={t('text128')}
                onClick={() => handleTabClick(6)}
                active={activeTab === 6}            
                />
                <Tab
                label={t('viaja_grupo')}
                onClick={() => handleTabClick(7)}
                active={activeTab === 7}
                />
                 <Tab
                label={t('text140')}
                onClick={() => handleTabClick(8)}
                active={activeTab === 8}
                />
            </div>
            </div>
            <div className="content-container">
            
                {activeTab === 0 && 
                <div className='info_antes_viaje'>
                    <h4>{t('text1')}</h4>
                    <p>{t('text2')}</p>
                    <ul>
                        <li>1. {t('text3')}</li>
                        <li>2. {t('text4')}<label><Link to="/contacto">{t('text5')}</Link></label>  </li>
                        <li>3. {t('text6')}<a href="tel:8003377437" rel="noopener noreferrer"><span >{t('text7')}</span></a></li>
                        <li>4. {t('text8')}<label><a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">{t('text9')}</a> </label> </li>
                    </ul>
                    <p>{t('text10')} </p>
                    <p>{t('text11')} </p>
                    <p>{t('text12')}<label><Link to="/tarifas">{t('text13')}</Link></label>  {t('text14')}y <label><Link to="/promociones">{t('text15')} </Link></label>.</p>
                    <p>{t('text16')}</p>
                    <p> <strong>{t('text17')}:</strong>  {t('text18')}:</p>                                                               
                    <ul>
                        <li>1. {t('text19')}</li>
                        <li>2. {t('text20')}</li>
                        <li>3. {t('text21')} {t('text22')} <label><Link to="/rutas_horario">{t('text23')}</Link> {t('text24')}y <Link to="/tarifas">{t('text25')}</Link></label> </li>
                        <li>4. {t('text26')}</li>
                    </ul>
                
                    <p><label><Link to="/aviso-de-privacidad">{t('text27')}</Link>/<Link to="/condiciones-de-servicio">{t('text28')}</Link></label> </p>
                    <p>
                        <strong>{t('text29')}:</strong> {t('text30')}  <label><Link to="/contacto">{t('text31')} </Link> </label>.
                    </p>
                    <p>{t('text32')} </p>

                </div>}
                {activeTab === 1 && 
                <div className='info_antes_viaje'>
                    <h4>{t('text33')}  </h4>
                    <p><strong>{t('text34')}:</strong> {t('text35')}</p>
                    <p><strong>{t('text36')}:</strong> {t('text37')}</p>
                    <p><strong>{t('text40')}:</strong> {t('text41')}</p>
                </div>}
                {activeTab === 2 && 
                <div className='info_antes_viaje'>                           
                        <h4>{t('text42_titulo')} </h4>
                        <p>{t('text42')}</p>
                        <ul>
                            <li>a) {t('text43')}</li>
                            <li>b) {t('text44')}</li>
                            <li>c) {t('text45')}.</li>
                            <li>d) {t('text46')}</li>
                            <li>e) {t('text47')}</li>
                            <li>f) {t('text48')}</li>
                        </ul>
                        <p>{t('text49')}</p>
                    </div>}
                {activeTab === 3 && <div className='info_antes_viaje'>
                    <h4>{t('text50')}</h4>
                                        <p>{t('text51')}</p>
                                        <p>{t('text52')}</p>
                                        <p><label><Link to="/aviso-de-privacidad">{t('text53')}</Link>/<Link to="/condiciones-de-servicio">{t('text54')}</Link></label> </p>
                                        </div>}
                {activeTab === 4 && <div className='info_antes_viaje'> <h4>{t('text55')} </h4>
                                        <p><strong>{t('text56')}:</strong> {t('text57')}</p>
                                        <p>{t('text58')}</p>
                                        <p><strong>{t('text59')}:</strong> {t('text60')}</p>
                                        <p>{t('text61')}</p>
                                        <p> <strong>{t('text62')}:</strong> {t('text63')} <label><a href='https://www.gob.mx/tramites/ficha/internacion-al-territorio-nacional-de-personas-extranjeras-titulares-de-una-visa-ordinaria-valida-y-vigente-expedida-por-el-gobierno-mexicano/INM619' target="_blank" rel='noopener noreferrer' > {t('text64')} </a></label>,  {t('text65')} </p>                                
                                        <p> <strong>{t('text66')}:</strong> {t('text67')}: </p>
                                        <ul>
                                            <li>1. {t('text68')}</li>
                                            <li>2. {t('text69')} </li>
                                            <li>3. {t('text70')}</li>
                                            <li>4. {t('text71')}</li>
                                            <li>5. {t('text72')}</li>
                                        </ul>
                                        <p> <strong>{t('text73')}:</strong> {t('text74')} </p>
                                        <ul>
                                            <li>{t('text75')}</li>
                                            <li>{t('text76')}</li>
                                            <li>{t('text77')}</li>
                                        </ul>
                                        <p>{t('text78')}:</p>
                                        <ul>
                                            <li>{t('text79')}</li>
                                            <li>{t('text80')}</li>
                                            <li>{t('text81')}</li>
                                            <li>{t('text82')} </li>
                                        </ul>
                                        <p>{t('text83')}<label><a href='https://www.banjercito.com.mx/registroVehiculos/' target="_blank" rel='noopener noreferrer' > {t('text84')}</a> </label></p>

                                    </div>}
                {activeTab === 5 && 
                <div className='info_antes_viaje'>
                    <h4>{t('text85')} </h4>
                        <p><strong>{t('text86')}:</strong> {t('text87')}</p>                                
                        <p><strong>{t('text88')}:</strong> {t('text89')}</p>                                
                        
                        <table class="table table-bordered mascota-tabla">
                            <tbody>            
                                <tr>                                    
                                    <th colSpan={2} className="">{t('text90')}	</th>
                                    <th colSpan={3} className="">{t('text91')}</th>                                    
                                </tr>
                                <tr>
                                    <td>{t('text92')}</td>
                                    <td className="">{t('text95')}</td>
                                    <td className="">{t('text106')}</td>
                                    <td className="">{t('text107')}</td>
                                    <td className="">{t('text113')}</td>
                                </tr>
                                <tr>
                                    <td>{t('text93')}</td>
                                    <td className="">{t('text96')} </td>
                                    <td className="">{t('text101')}</td>
                                    <td className="">{t('text108')}</td>
                                    <td className="">{t('text114')}</td>
                                </tr>
                                <tr>
                                    <td>{t('text94')}</td>
                                    <td className="">{t('text97')}</td>
                                    <td className="">{t('text102')}</td>
                                    <td className="">{t('text109')}</td>
                                    <td className="">{t('text115')}</td>			        					      
                                </tr>	
                                <tr>
                                    <td></td>
                                    <td className="">{t('text98')}</td>
                                    <td className="">{t('text103')}</td>
                                    <td className="">{t('text110')}</td>
                                    <td className="">{t('text116')}</td>	
                                </tr>	
                                <tr>
                                    <td></td>
                                    <td className="">{t('text99')}</td>
                                    <td className="">{t('text104')}</td>
                                    <td className="">{t('text111')}</td>
                                    <td className="">{t('')}</td>	
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="">{t('text100')} </td>
                                    <td className="">{t('text117')}</td>
                                    <td className="">{t('text112')}</td>
                                    <td className="">{t('text118')}</td>	
                                </tr>                                			                                            
                            </tbody>
                        </table>
                        
                        <p>{t('text119')}</p>
                        <p>{t('text120')}</p>
                        <p>{t('text121')} </p>
                        <p>{t('text122')}</p>
                        <p><strong>{t('text123')}: </strong> {t('text124')} </p>
                        <p>{t('text125')}<label  onClick={() => handleTabClick(4)} style={{color:'#0075C9', cursor: 'pointer', textDecoration: 'underline'}}>{t('text126')}</label> {t('text127')}</p>                                                                
                </div>}

                {activeTab === 6 && <div className='info_antes_viaje'>
                    <h4>{t('text128')}</h4>
                    <p>{t('text129')}</p>
                    <p>{t('text130')} </p>
                    <p>{t('text131')} <label><Link to="/tarifas">{t('text132')}</Link></label> {t('text133')} <label onClick={() => handleTabClick(4)} style={{color:'#0075C9', cursor: 'pointer', textDecoration: 'underline'}}>{t('text134')}</label> {t('text135')} </p>                                    
                </div>}
                {activeTab === 7 && <div className='info_antes_viaje'>
                    <h4>{t('viaja_grupo')} </h4>
                    <p>{t('text136')}</p>
                    <p>{t('text137')} <label><Link to="/viaje-en-grupo">{t('text138')}</Link> </label> {t('text139')} </p>
                    </div>}
                {activeTab === 8 && <div className='info_antes_viaje'>
                <h4>{t('text140')}</h4>
                <p>{t('text141')}: </p>
                <p><strong>{t('text142')}:</strong> {t('text143')}</p>
                <p>* {t('text144')}.</p>
                </div>
                }
                
            </div>
        </div>
    </div>
   </sections>


   <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Promociones y Descuentos</h4>
                                </div>
                                <div>
                                    <p>Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/promociones">Ver más</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={reservar} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Listo para reservar?</h4>
                                </div>
                                <div>
                                    <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <a href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a>        
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={rutas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Rutas y Horarios</h4>
                                </div>
                                <div>
                                    <p>Explora nuestras rutas y encuentra el horario que mejor se ajuste a tus planes de viaje.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/rutas_horario">Ir ahora</Link>                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <Outlet />
    <Footer />
    
    </>
  )
}

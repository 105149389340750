import React, { useState } from 'react';
import '../css/promocion_horizontal.css';
import { Outlet, Link } from "react-router-dom";

import abordo from '../assets/images/promociones/carga-promo-abordo-volante.webp'
const Promociones_horizontal = () => {
  const items = [
    { id: 1, enlace:'/transportando-carga', text: 'Aprovecha el buen fin', subtext:'Compra ahora y obtén 12 MSI', imageUrl: './images/promociones/transportamas.webp' },
    { id: 2, enlace:'/promo-adulto-mayor', text: 'Celebra el 20 de noviembre', subtext:'Con descuentos de revolución', imageUrl: './images/promociones/inapam.webp' },
    { id: 3, enlace:'/promo-ninos', text: 'Obtén un 30% de descuento', subtext:'Al presentar tu credencial de INAPAM', imageUrl: './images/promociones/promocion_ninos-cuadrado.webp' },    
    
    
  ];
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftButton, setShowLeftButton] = useState(true);
  const [showRightButton, setShowRightButton] = useState(false);

  const handleScrollRight = () => {
    setScrollPosition((prevPosition) => prevPosition + 310);   
    setShowLeftButton(true);
    setShowRightButton(false); 
    console.log("scrollPositionRight", scrollPosition);      
  };

  const handleScrollLeft = () => {
    setScrollPosition((prevPosition) => prevPosition - 310);
    if(scrollPosition === -620)
    {
      setShowLeftButton(false);
      setShowRightButton(true);
    }
    console.log("scrollPositionLeft", scrollPosition);
  };


  return (
    <>
        <div className='promociones_title_home_phone'>
            <div>
              <h2>Promociones</h2>
                <p>Encuentra ofertas increíbles</p>
            </div>
            {/*
            <button onClick={handleScrollLeft} style={{ display: showLeftButton ? 'inline-block' : 'none' }}>
                <i className="fi fi-rr-angle-right"></i>
            </button>
            <button onClick={handleScrollRight} style={{ display: showRightButton ? 'inline-block' : 'none' }}>
                <i className="fi fi-sr-angle-left"></i>
            </button>  
            */}          
        </div>
        {/*}
        <div className="horizontal-scroll-container ">
            <div className="scroll-content" style={{ transform: `translateX(${scrollPosition}px)` }}>
                {items.map((item) => (
                <div key={item.id} className="scroll-item">
                   <Link to={item.enlace}> 
                    <div className='box_destino_phone'>
                        <img src={item.imageUrl} alt={`Imagen ${item.id}`} />
                        {/*<div className='box_promociones_text_phone'>
                            <p style={{color:'#545454'}}>{item.text}</p>
                            <p>{item.subtext}</p>
                        </div>/}
                    </div>
                    </Link> 
                </div>
                ))}
            </div>    
        </div> 
        */}
          <div className='banner-cancer'>
              <Link to="/promociones">  <img src={abordo} alt='promo-abordo-volante baja ferries' /></Link>
          </div>
        <Outlet />
    </>
  );
};

export default Promociones_horizontal;


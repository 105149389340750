import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import '../css/noticia_nota.css'
import { Outlet, Link } from "react-router-dom";
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import Footer from '../components/Footer'




const Noticia= () => {
    const [alert, setAlert] = useState(false);
    const [noticia, setNoticia] = useState([]);
    const [task, setTask] = useState({
        email:'',
        fecha_alta: '',
        fecha_baja: null,
    })
    
    

    const params = useParams();
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('es-ES', options);
        return formattedDate;
    };

    const loadTask = async (id) => {
        //const response = await fetch(`http://localhost:3004/noticias/${id}`);
        const response = await fetch(`https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/noticias/${id}`);
        const data = await response.json();
        //console.log(data);
        setNoticia({photo_route_header: data.photo_route_header, title: data.title, description: data.description, photo_route_footer: data.photo_route_footer, category: data.category, creation_date: formatDate(data.creation_date), modification_date: data.modification_date, user_manager: data.user_manager, creation_time: data.creation_time, visible: data.visible});

    }
    
    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
        setTask((prevTask) => ({ ...prevTask, fecha_alta: currentDate }));
    }, []);

    useEffect(() =>{
        if(params.id){
            loadTask(params.id);
        }
        
    },[params.id]);

    if (!noticia) {
        return <div>Cargando...</div>
    }

    
    
   

    
    const handleChange = (e) => {

        setTask({...task, [e.target.name]: e.target.value});
        console.log("task", task);
    }




    const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("task", task);

    try {
        //await axios.post('http://localhost:3004/newsletter', {
        //await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/enviar-correo', {
        await fetch('http://localhost:3001/newsletter',{
        //await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/newsletter',{
            method:'POST',
            body: JSON.stringify(task),
            headers: {'Content-Type': 'application/json'},
        });

        // Limpiar campos después de enviar el correo
        setTask({
            email: '',
            fecha_alta: new Date().toISOString().split('T')[0],
            fecha_baja: '',
        });
        setAlert(true);
        console.log('Formulario enviado con éxito');
        
    } catch (error) {
        console.error('Error al enviar el correo:', error);
    }
    }

  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Noticias</h3>
                        <p>Planifica tu próximo viaje con nosotros ¡Elige el día y reserva ahora!</p>
                    </div>                
                </div>
            </div>
        </section>
    <div className='container'>
        <div className='opc_regresar'>
            <Link to="/noticias"><i class="fi fi-rr-undo-alt"></i> Todas las noticias</Link>  
        </div>
        <hr />
              
    </div>
    <section className='noticia_container'>
        <div className='container'>
            <div className='content_noticia_nota'>        
                <div className='nota_content'>
                    
                    <div className='nota_content_img_header'>
                    {noticia.photo_route_header && (
                        <img src={'../images/noticias/'+noticia.photo_route_header} alt='baja ferries' />
                    )}
                    </div>
                    <div className='tag_line'>
                        <p><i class="fi fi-rs-calendar"></i> {noticia.creation_date}</p>
                        <p><i class="fi fi-rs-bookmark"></i> {noticia.category}</p>
                    </div> 
                    <h1>{noticia.title}</h1>
                    {/*<p>{noticia.description}</p>        */}
                    <div dangerouslySetInnerHTML={{ __html: noticia.description }}></div>
                </div>        
                <div className='lateral_box'>
                    <div className='noticias_box_redes'>
                        <h2>Síguenos en nuestras redes</h2>
                        <div>
                                <ul class="list-inline">
                                    <li><a href='https://www.facebook.com/bajaferriesoficial' target="_blank" rel='noopener noreferrer'><i class="fi fi-brands-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/bajaferriesoficial/?hl=es" target="_blank" rel='noopener noreferrer'><i class="fi fi-brands-instagram"></i></a></li>
                                    <li><a href="https://twitter.com/bajaferries" target="_blank" rel='noopener noreferrer'><i class="fi fi-brands-twitter-alt-circle"></i></a></li>								
                                    <li><a href="https://www.linkedin.com/company/19058976/admin/feed/posts/" target="_blank" rel='noopener noreferrer'><i class="fi fi-brands-linkedin"></i></a></li>
                                    <li><a href="https://www.youtube.com/@bajaferries2914" target="_blank" rel='noopener noreferrer'><i class="fi fi-brands-youtube"></i></a></li>
                                </ul>
                        </div>
                        
                    </div>
                    <div className='noticias_box_'>
                    

                    </div>
            
                    <div className='noticias_box_newsletter'>
                        <h2>Suscríbete a nuestro newsetter</h2>
                        <p>Ingresa tu correo electrónico para recibir noticias, avisos y promociones especiales. </p>
                        
                        {alert &&(                
                        <div className='alertMessage_footer'>
                            <label>¡Te has suscrito correctamente! </label>                
                        </div>
                        )}
                        <form onSubmit={handleSubmit} className='footer_newsletter'>                           
                            <input type='email' name='email' value={task.email} onChange={handleChange} placeholder='NEWSLETTER'/>
                            <button type='submit'  disabled={!task.email} style={{ backgroundColor: '#225981', border: '1px solid #225981'}}> <i class="fi fi-ss-angle-right"></i> </button>
                        </form>
             
                    </div>
                    <div className='noticias_box_prensa'>
                        <h2>Contacto de prensa</h2>
                        <div>
                            <p>Comunicación Corporativa Baja Ferries</p>
                            <p>The CloseUp Company (Agencia de RP)</p>
                        </div>
                        <div className='box_prensa'>
                            <p>Marco Contreras</p>
                            <p>mcontreras@closeupcompany.com</p>
                            
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
    </section>

    
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Conoce más acerca de nosotros</h4>
                            </div>
                            <div>
                                <p>Aprende sobre nuestros valores y la historia detrás de 21 años de experiencia. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/nosotros">Más información</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Nuestras certificaciones</h4>
                            </div>
                            <div>
                                <p>Conoce las certificaciones y distintivos que avalan la seguridad y la calidad de viajar con nosotros. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/certificaciones">Más información</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Comunícate con nosotros</h4>
                            </div>
                            <div>
                                <p>Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a>              
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
    <Footer />
    
    
    
    </>
    
  );
};

export default Noticia;


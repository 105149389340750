import React from 'react'
import '../css/fleetservice.css';
import california from '../assets/images/flota/cali/CALSTAR-cabina01.webp'
import entretenimiento from '../assets/images/flota/cali/entretenimiento.jpg'
import salon from '../assets/images/flota/cali/CALSTAR-salonturista1.webp'
import salonEjecutivo from '../assets/images/flota/cali/Foto_Salon Ejecutivo.jpg'
import restaurante from '../assets/images/flota/cali/CALSTAR-restaurant1.webp'
import { useTranslation } from 'react-i18next';
import doctor from '../assets/images/cirujano.png'
import Footer from './Footer'

export default function Fleetservice_cali() {
    const { t } = useTranslation('servicioCalifornia');
  return (
    <>
    
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>California Star</h3>
                    <p>{t('header_section_title_p')}</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='fleetserices_ship'>   
        <section className='fleetserices'>
            <div className='container'>
                <div className='fleetserices_content'>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={california} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_cabinas')}</h2>
                                <p>{t('fleetserices_cabinas_text')}</p>
                                
                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={salon} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_salon')}</h2>
                                <p>{t('fleetserices_salon_text')}</p>
                                
                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={salonEjecutivo} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_salon_ejecutivo')}</h2>
                               
                                <p>{t('fleetserices_salon_ejecutivo_text')} </p>
                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={restaurante} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_restaurante')}</h2>
                                <p>{t('fleetserices_restaurante_text1')}</p>
                                <p>{t('fleetserices_restaurante_text2')}</p>
                                <p>{t('fleetserices_restaurante_text3')}</p>
                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                  
                </div>
            </div>
        </section>
        <section className='fleetserices_bottom entretenimiento'>
            <div className='container'>
                <div className='fleetserices_content'>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img'>
                                <img src={entretenimiento} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text'>
                                <h2>{t('fleetserices_entretenimiento')}</h2>
                                <p>{t('fleetserices_entretenimiento_text1')}</p>
                                <p>{t('fleetserices_entretenimiento_text2')}</p>

                                <div className='fleetserices_btn'>
                                    <button>{t('fleetserices_btn')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>
        <section className='fleetserices_medico'>
            <div className='container'>
                <div className='fleetserices_content medico'>
                    <div className='fleetserices_box'>
                        <div className='fleetserices_img_box'>
                            <div className='fleetserices_img_medico'>
                                <img src={doctor} alt='california star flota baja ferries' />
                            </div>
                        </div>
                        <div className='fleetserices_text_box'>
                            <div className='fleetserices_text_medico'>
                                <h2>{t('fleetserices_medico')}</h2>
                                <p>{t('fleetserices_medico_text')}</p>                                
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </section>
    </section>
    <Footer/>
    </>
  )
}

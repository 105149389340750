import React, {useState, useEffect} from 'react'
import axios from 'axios';
import '../css/footerprueba.css'
import logo from '../assets/images/LOGO-BF-BLANCO.png'
import facebook from '../assets/images/spotify/facebook.svg';
import twitter from '../assets/images/spotify/twitter.svg';
import instagram from '../assets/images/spotify/instagram.svg';
import youtube from '../assets/images/spotify/youtube.svg';
import spotify_logo from '../assets/images/spotify/spotify.svg';
import { useTranslation } from 'react-i18next';
import { Outlet, Link } from "react-router-dom";

export default function Footer() {
    const { t } = useTranslation('footer');
    
    const [task, setTask] = useState({
        email:'',
        fecha_alta: '',
        fecha_baja: null,
    })
    const [alert, setAlert] = useState(false);

    useEffect(() => {
        const currentDate = new Date().toISOString().split('T')[0]; // Formato: YYYY-MM-DD
        setTask((prevTask) => ({ ...prevTask, fecha_alta: currentDate }));
    }, []);


    const handleChange = (e) => {

        setTask({...task, [e.target.name]: e.target.value});
        console.log("task", task);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("task", task);

        try {
            //await axios.post('http://localhost:3004/newsletter', {
            //await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/enviar-correo', {
            //await fetch('http://localhost:3001/newsletter',{
            await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/newsletter',{
                method:'POST',
                body: JSON.stringify(task),
                headers: {'Content-Type': 'application/json'},
            });
    
            // Limpiar campos después de enviar el correo
            setTask({
                email: '',
                fecha_alta: new Date().toISOString().split('T')[0],
                fecha_baja: '',
            });
            setAlert(true);
            console.log('Formulario enviado con éxito');
            
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    }

  return (
    <>
    <section class="footer">

       <div className='container'>
            <div className='footer_content'>
                <div className='footer_main_box'>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <img src={logo} alt='logo baja ferries blanco' />
                    </div>                    
                    <p>{t('text1')}</p>                
                    {alert &&(                
                        <div className='alertMessage_footer'>
                            <label>¡Te has suscrito correctamente! </label>                
                        </div>
                        )}
                    <form onSubmit={handleSubmit} className='footer_newsletter'>                           
                        <input type='email' name='email' value={task.email} onChange={handleChange} placeholder='NEWSLETTER'/>
                        <button type='submit'> <i class="fi fi-ss-angle-right"></i> </button>
                    </form>
             
                </div>
                <div className='footer_container'>
                    <div className='footer_box'>                        
                        <ul>                            
                            <li><Link to="/nosotros">{t('text2')}</Link> </li>
                            <li><Link to="/flota">{t('text3')} </Link></li>
                            <li><Link to="/certificaciones">{t('text4')}</Link></li>
                            <li><Link to="/soluciones-logisticas">{t('text5')}</Link></li>
                            <li><Link to="/rutas_horario">{t('text6')} </Link></li>
                            <li><Link to="/tarifas">{t('text7')}</Link></li>
                            {/*<li><Link to="/formulario-de-contacto?viajoEnGrupo=true">Viaje en grupo</Link></li>viaje-en-grupo*/}
                            <li><Link to="/viaje-en-grupo">{t('text8')} </Link></li>
                            <li><Link to="/promociones">{t('text9')}</Link></li>                             
                        </ul>
                    </div>
                    <div className='footer_box'>                    
                        <ul>                            
                            <li><Link to="/prepara-tu-viaje">{t('text10')}</Link></li>   
                            <li><Link to="/destinos">{t('text11')}</Link></li>                                                 
                            <li><Link to="/noticias">{t('text12')}</Link></li>
                            <li><Link to="/antes-de-viajar">{t('text13')}</Link></li>                                                 
                            <li><Link to="/durante_tu_viaje">{t('text14')}</Link></li>
                            <li><Link to="/al-llegar">{t('text15')}</Link></li>                                                 

                            {/*<li><Link to="/sostenibilidad">Sostenibilidad</Link></li> 
                            <li><Link to="/viaje-en-curso">Viaje en curso</Link></li>                            
                            <li><Link to="/kit-de-comunicacion">Prensa</Link></li>                            
                            <li><a className="navbar-link" href="https://bajaferries.com.mx/facturacion/" target="_blank" rel="noopener noreferrer">Facturación</a> </li>*/}            
                            <li><a className="navbar-link" href="http://proveedores.bajaferries.com/ProveedoresLogin.aspx" target="_blank" rel="noopener noreferrer">{t('text16')}</a></li>                                
                            <li><a className="navbar-link" href="https://booking.bajaferries.com.mx/es/cuenta/" target="_blank" rel="noopener noreferrer">{t('text17')}</a></li>  
                        </ul>                        
                    </div>
                    <div className='footer_box'>                    
                        <ul>                          
                            <li><Link to="/contacto">{t('text18')}</Link></li>    
                            <li><Link to="/preguntas-frecuentes">{t('text19')}</Link></li>
                            <li><a className="navbar-link" href="https://eticabajaferries.lineaetica.com.mx/" target="_blank" rel="noopener noreferrer">{t('text20')}</a></li>                            
                            <li><a className="navbar-link" href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es" target="_blank" rel="noopener noreferrer">{t('text21')}</a></li>
                            <li><a className="navbar-link" href="https://eticabajaferries.lineaetica.com.mx/form?bu=baja_ferris" target="_blank" rel="noopener noreferrer">{t('text22')}</a></li>                                
                            <li><Link to="/condiciones-de-servicio">{t('text23')}</Link></li>
                            <li><Link to="/aviso-de-privacidad">{t('text24')}</Link></li>
                            <div className='box_socials_links carga'>
                                <div className='box_link_social'>
                                    <a href="https://www.facebook.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={facebook} alt='facebook oficial bajaferries' className='bg_spotify' /> </a>                                                                            
                                </div>
                                <div className='box_link_social'>                                        
                                    <a href="https://x.com/bajaferries" rel='noopener noreferrer' target="_blank"><img src={twitter} alt='twitter oficial bajaferries' className='bg_spotify' /> </a>                                      
                                </div>
                                <div className='box_link_social'>
                                    <a href="https://www.instagram.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={instagram} alt='instagram oficial bajaferries' className='bg_spotify' /> </a>                                                                                
                                </div>
                                <div className='box_link_social'>
                                    <a href="https://www.youtube.com/@bajaferries2914" rel='noopener noreferrer' target="_blank"><img src={youtube} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                                </div>   
                                <div className='box_link_social'>
                                    <a href="https://open.spotify.com/user/in0rh2go0af0k6asr46bzxun7" rel='noopener noreferrer' target="_blank"><img src={spotify_logo} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                                </div>                         
                            </div>
                        </ul>
                    </div>
                </div>
                
            </div>
            
       </div>
       <div className='footer_copyright'>
            <div className='container'>
                <div>
                    <label>Copyright © BAJA FERRIES S.A.P.I. DE C.V. 2024. </label>
                    <label>{t('text25')} </label>
                </div>
             
            </div>
        </div>

    </section>
    <Outlet />
    </>
  )
}

import React from 'react';
import '../css/preguntas_frecuentes.css';
import { Col, Row,  } from 'antd';
import { Outlet, Link } from "react-router-dom";
import bookingIcon from '../assets/images/iconsPreguntas/equipaje-de-viaje2.png'
import reservar from '../assets/images/iconsPreguntas/calendario1.png'
import documentacion from '../assets/images/iconsPreguntas/documentacion.png'
import cargoShip  from "../assets/images/iconsPreguntas/barco-de-carga.png";
import Footer from '../components/Footer'

import avisos from '../assets/images/iconsPreguntas/coche.png'
import pagos_facturas from '../assets/images/iconsPreguntas/factura.png'

import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import { useTranslation } from 'react-i18next';


const PreguntasFrecuentes = () => {
    const { t } = useTranslation('preguntasFrecuente');
    const questionsAntes = [
        {   id: 3,
            text: t('text4'),
            answer: 'Identificación oficial vigente, expedida por el Instituto Nacional Electoral (INE), Pasaporte vigente, Cédula profesional vigente con fotografía, En el caso de menores de edad: acta de nacimiento, credencial emitida por Instituciones de Educación Pública o Privada con reconocimiento de validez oficial con fotografía y firma o pasaporte. Si eres extranjero: Pasaporte o documento migratorio vigente que corresponda, emitido por autoridad competente (en su caso, prórroga o refrendo migratorio)',
        },
        {
            id: 2,
            text: t('text5'),
            answer:  <>
            <p>Te recomendamos abordar el buque con un equipaje ligero de mano con lo necesario para tu viaje que cumplan las siguientes condiciones:</p>
            <ul>
                <li>El peso permitido por equipaje de mano es de 10 kg máximo</li>
                <li>Puede medir hasta 57 x 40 x 33 cm (dimensión total).</li>
            </ul>
            <p>Para mayor seguridad y confort de los pasajeros, el equipaje de mano que sobre pase el peso o dimensiones mencionadas será documentado.</p>
            <p>Debes tomar las precauciones debidas para proteger e identificar tu equipaje, ya que la empresa no se hace responsable por la pérdida de objetos personales.</p>
            </>
        },
        {
            id: 8,
            text: t('text6'),
            answer: 'El horario de salida dependerá del puerto y el día que busques viajar. Te invitamos a visitar nuestra seccion de Rutas y horarios para consultar tu posible itinerario.',
        },
    
    ];
    const questionsReserva = [
        {   id: 1,
            text: t('text8'),
            answer: 'El precio de los boletos varía según la edad del pasajero y la temporalidad en la que realices tu viaje. Te invitamos a visitar nuestra sección de Tarifas para encontrar la mejor opción para tu viaje.',
        },
        {
            id: 2,
            text: t('text9'),
            answer: 'Si es posible, en caso de que el pasajero no pueda viajar en su reservación original puede comunicarse al Contact center (800) 337 74 37 y solicitar dejar su boleto abierto, es importante considerar que este tendrá una vigencia de doce meses y que estos boletos requieren una confirmación 48 horas antes de la salida; en todos los casos un boleto abierto siempre está sujeto a disponibilidad de espacio.            Posteriormente cuando el cliente decida hacer uso de él, deberá cubrir un cargo por el cambio de la fecha de $580 pesos por cada boleto y en caso de existir una diferencia en la tarifa, deberá cubrirla.',
            
        },
        {
            id: 4,
            text: t('text10'),
            answer: 'La compra de tu boleto no inlcuye cabina, puedes agregar una a tu reservación al momento de tu compra o bien adquirirla antes de tu viaje. Contamos con cabinas limitadas, por lo que recomendamos reservar con anticipación. ',
        },
    
    ];
    const questionsDocumentacion = [
        {   id: 5,
            text: t('text12'),
            answer: ( 
                <>
                <p>Para que un vehículo nacional viaje con nosotros solo es necesario contar con la información principal del auto al momento de realizar la compra del boleto:</p>
                <ul>
                    <li>Número de placas y estado de procedencia</li>
                </ul>
                <p>Sin embargo, los puertos en los que operamos son recintos fiscalizados y el control de ingreso a las instalaciones se encuentra a cargo de API (Administración Portuaria Integral) o de ASIPONA (Administraciones del Sistema Portuario Nacional) </p>
                <p>Para ingresar un vehículo al puerto las autoridades le podrían solicitar el pedimento de importación para acreditar la nacionalidad del vehículo, por lo que te sugerimos llevar una copia de la factura del automóvil.</p>
                <p>Además fuera y dentro del puerto operan distintas autoridades quienes cuentan con controles de seguridad propios y podrían solicitarte documentación específica para corroborar el estatus legal del vehículo como:</p>
                <ul>
                    <li>Identificación oficial</li>                                   
                    <li>Tarjeta de circulación</li>
                    <li>Copia de la factura o carta factura</li>
                    <li>Póliza de seguro contra daños a terceros</li>
                </ul>
                <p>En caso de que tu vehículo sea fronterizo o tenga placas de otro país, necesitarás tramitar el permiso de “Importación Temporal”, el cual puedes obtener en las instalaciones de Banjercito. Puedes consultar las tarifas y documentos requeridos en su página web o al teléfono 6121234457</p>
                
                </>            
            ),
        },
        {
            id: 4,
            text: t('text13'),
            answer: 'Si es posible que un menor viaje sin sus padres o tutores legales, para esto, solicitamos que lleven una carta de autorización escrita y firmada por los padres especificando que autorizan que viaje con el adulto responsable de ellos, además deberán llevar una copia del INE de ambos padres.',
            
        },
        {
            id: 3,
            text: t('text14'),
            answer: 'Es necesario que los menores de edad viajen en compañía de una persona mayor a 18 años ',
        },
    
    ];
    const questionsPagos = [
        {   id: 1,
            text: t('text16'),
            answer: ( 
                <ul>
                    <li>Si, la facturación de tus boletos se deberá solicitar al momento de la compra brindando todos los datos actualizados</li>
                    <li>En caso de no solicitarla en ese momento, en las próximas 48 horas ingresando al portal de facturación de nuestra página web en el apartado "facturación"  </li>            
                </ul>
            ),
        },
        {
            id: 2,
            text: t('text17'),
            answer: ( 
                <ul>
                    <li>En puntos de venta recibimos pagos con tarjeta de débito o crédito y efectivo.</li>
                    <li>En el Contact center aceptamos pagos con deposito, transferencia, y tarjeta de débito o crédito.</li>            
                </ul>
            ),
            
        },
        {
            id: 4,
            text: t('text18'),
            answer: ( 
                <ul>
                    <li>La confirmación de tu reserva es tu recibo de pago y debe llegar de manera automática al correo que proporcionaste al momento de realizar tu pago.</li>
                    <li>En caso de no haberlo recibido puedes comunicarte a nuestro Contact Center para solicitar el reenvío. </li>            
                </ul>
            ),
        },
    ];
    const questionsAutomovil = [
        {
            id: 3,
            text: t('text20'),
            answer: 'Si, es posible enviar el automóvil solo, en esos casos, solicitamos designar un adulto con identificación oficial responsable de embarcarlo, y desembarcarlo, tambien solicitamos estar en el puerto en los horarios designados para evitar contratiempos en la operación.',
        },
        {
            id: 5,
            text: t('text21'),
            answer: 'Es obligatorio que las motocicletas viajen con su conductor, mismo que será responsable de su embarque y desembarque.',
        },
        {
            id: 1,
            text: t('text22'),
            answer: 'La tarifa del automóvil no incluye el operador, son tarifas separadas, por lo tanto, se deberá pagar el boleto del automóvil y del adulto.',
        },
    
    ];
    const questionsCarga = [
        {
            id: 10,
            text: t('text24'),
            answer: 'Si tu unidad viaja cargada, debes solicitar la carta porte en el SAT. Este documento es indispensable, ya que las autoridades portuarias lo solicitan al ingreso al puerto.',
        },
        {
            id: 11,
            text: t('text25'),
            answer: 'Solicitamos que todas las unidades de carga se presenten en la terminal portuaria con al menos 5 horas de anticipación.',
        },
        {
            id: 5,
            text: t('text26'),
            answer: 'Sí, contamos con un restaurante y un bar a bordo donde puedes comprar alimentos y bebidas. Además, el boleto incluye una comida de cortesía al embarcar.',
        },
    
    ];

    return (
        <>

        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>{t('preguntas_frecuentes')}</h3>
                        <p>{t('preguntas_frecuentes_p')}</p>
                    </div>                
                </div>
            </div>
        </section>
  
        <div className="container" id="content">
            <div className='box_preguntas_frecuentes_title'>
                <h3>{t('text1')}</h3>
                {/*<p>Hemos recopilado respuestas detalladas a las consultas más comunes que nuestros clientes suelen tener. Acceder a esta sección te permitirá obtener información rápida sobre temas los temas más destacados </p>*/}
                <p>{t('text2')}</p>
            </div>
            <Row>
                <Col span={12}>
                    <div className='box_group-card'>
                        <div className='box_content-card'>
                            <div className='box_icon-card'>
                                <img src={bookingIcon} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>{t('text3')}</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <ul>                                                                         
                                        {questionsAntes.map(question => (
                                        <li key={question.id}>
                                            <Link to={`/preguntas-frecuentes-antes-viajar/${question.id}`}>{question.text}</Link>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='box_content-btn'>                         
                                    <Link to="/antes-de-viajar-faqs">{t('text27')}</Link>                                                                         
                                </div>
                            </div>                            
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='box_group-card'>
                        <div className='box_content-card'>
                            <div className='box_icon-card'>
                                <img src={reservar} alt="cargoShip" className='cargoShip' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>{t('text7')}</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <ul>
                                        {questionsReserva.map(question => (
                                        <li key={question.id}>
                                            <Link to={`/preguntas-frecuentes-reserva/${question.id}`}>{question.text}</Link>
                                        </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="/reserva">{t('text27')}</Link>                                                                         
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>
            <Row>                
                <Col span={12}>
                    <div className='box_group-card'>
                        <div className='box_content-card'>
                            <div className='box_icon-card'>
                                <img src={documentacion} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>{t('text11')}</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <ul>                                                                          
                                    {questionsDocumentacion.map(question => (
                                        <li key={question.id}>
                                            <Link to={`/preguntas-frecuentes-documentacion/${question.id}`}>{question.text}</Link>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="/documentacion">{t('text27')} </Link>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='box_group-card'>
                        <div className='box_content-card'>
                            <div className='box_icon-card'>
                                <img src={pagos_facturas} alt="pagos_facturas" className='pagos_facturas' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>{t('text15')}</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <ul>                                          
                                        {questionsPagos.map(question => (
                                            <li key={question.id}>
                                                <Link to={`/preguntas-frecuentes-pagos-facturas/${question.id}`}>{question.text}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="/pagos-facturas">{t('text27')}</Link> 
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>                        
            <Row>
                
                <Col span={12}>
                    <div className='box_group-card'>
                        <div className='box_content-card'>
                            <div className='box_icon-card'>
                            <img src={avisos} alt="cargoShip" className='cargoShip' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>{t('text19')}</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <ul>                                          
                                        {questionsAutomovil.map(question => (
                                            <li key={question.id}>
                                                <Link to={`/preguntas-frecuentes-automovil/${question.id}`}>{question.text}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                    
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="/automovil">{t('text27')}</Link> 
                                                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className='box_group-card'>
                        <div className='box_content-card'>
                            <div className='box_icon-card'>
                                <img src={cargoShip } alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>{t('text23')} </h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <ul>                                          
                                        {questionsCarga.map(question => (
                                            <li key={question.id}>
                                                <Link to={`/preguntas-frecuentes-carga/${question.id}`}>{question.text}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                    
                                </div>
                                <div className='box_content-btn'>                
                                    <Link to="/carga">{t('text27')}</Link>                                     
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>
        </div>

        <Outlet />
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title1')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title1')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/flota">{t('cta_btn1')}</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title2')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title2')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es" target="_blank" rel="noopener noreferrer">{t('cta_btn2')}</a>
                            
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>Tu{t('cta_title3')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        {/*<a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a> */}                       
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">{t('cta_btn3')} </a>  
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </section>
        <Footer />
      

        </>
      
    );
  };
  
  export default PreguntasFrecuentes;




import React, {  useState } from 'react';
import '../css/durante_viaje.css'
import { Outlet, Link } from "react-router-dom";
import Footer from '../components/Footer';
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/pregutas.png'
import { useTranslation } from 'react-i18next';

const Tab = ({ label, onClick, active, icon }) => {
    return (
      <div className={`tab_viajar ${active ? 'active' : ''}`} onClick={onClick}>       
        <p>{label}</p>
      </div>
    );
};


export default function Durante_viaje() {
    const { t } = useTranslation('duranteViaje');
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };    

  return (
    <>
    <section id="hero-section">
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('duranteViaje_titulo')}Durante tu viaje</h3>
                    <p>{t('duranteViaje_p')}Consulta los requisitos para viajar y evita contratiempos</p>
                </div>                
            </div>
        </div>                                 
    </section>
  
   <sections className='info_durante_viajar'>
 

   <div className="container">
        <div className='tabs_viajar'>
            <div className="tab-container">
            <div className="tab-boxes">
                <Tab
                label={t('text1')}
                onClick={() => handleTabClick(0)}
                active={activeTab === 0}
                
                />
                <Tab
                label={t('text4')}
                onClick={() => handleTabClick(1)}
                active={activeTab === 1}
                
                />
                <Tab
                label={t('text6')}
                onClick={() => handleTabClick(2)}
                active={activeTab === 2}
                
                />
                <Tab
                label={t('text8')}
                onClick={() => handleTabClick(3)}
                active={activeTab === 3}
                
                />
                <Tab
                label={t('text11')}
                onClick={() => handleTabClick(4)}
                active={activeTab === 4}
                
                />               
            </div>
            </div>
            <div className="content-container">                            
                {activeTab === 0 && 
                <div className='info_antes_viaje'>
                    <h4>{t('text1')}</h4>
                    <p>{t('text2')} </p>                    
                    <p>{t('text3')}</p>
                    
                </div>}
                {activeTab === 1 && 
                <div className='info_antes_viaje'>
                    <h4>{t('text4')}</h4>
                    <p>{t('text5')}</p>
                    
                </div>}
                {activeTab === 2 && 
                <div className='info_antes_viaje'>                           
                        <h4>{t('text6')}  </h4>
                        <p>{t('text7')}</p>
                        
                    </div>}
                {activeTab === 3 && 
                    <div className='info_antes_viaje'>
                        <h4>{t('text8')}</h4>
                        <p>{t('text9')}</p>
                        <p>{t('text10')}</p>
                        
                    </div>}
                {activeTab === 4 && 
                    <div className='info_antes_viaje'> 
                        <h4>{t('text11')}</h4>
                        <p>{t('text12')} </p>
                        <p>{t('text13')}</p>                    
                    </div>}                               

            </div>
        </div>
    </div>
   </sections>
   <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Nuestra flota</h4>
                                </div>
                                <div>
                                    <p>Conoce más acerca de nuestros buques y los servicios que cada uno ofrece abordo ¡Elige en cúal será tu próximo viaje! </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/flota">Ver más</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Aún tienes dudas?</h4>
                                </div>
                                <div>
                                    <p>Visita nuestra sección de preguntas frecuentes, donde encontrarás más información para evitar un viaje sin contratiempos.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/preguntas-frecuentes">Más información</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Explora tu destino</h4>
                                </div>
                                <div>
                                    <p>Te platicamos sobre algunos de los increíbles lugares que puedes conocer a tu llegada. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <Link to="/destinos">Ir ahora</Link>                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <Outlet />
    <Footer />
    
    </>
  )
}

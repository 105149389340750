import React, { useState, useEffect } from 'react';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/locale/es_ES';
import '../../css/reserva.css';
import '../../css/datepicker.css';
import sinVehiculo from '../../assets/images/iconReserva/camion-recogida_nocar.svg'
import motocicleta from '../../assets/images/iconReserva/motocicleta.svg'
import vehiculo from '../../assets/images/iconReserva/camion-recogida-2.svg'
import motorhome from '../../assets/images/iconReserva/casa-rodante.svg'
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;



const Reserva = () => {
	const { t } = useTranslation('reserva');
	const [boxPasajero, setBoxPasajero] = useState(false);
	const [boxAuto, setBoxAuto] = useState(false);
	const [adultoCount, setAdultoCount] = useState(1);
	const [infanteCount, setInfanteCount] = useState(0);
	const [menorCount, setMenorCount] = useState(0);
	const [mayorCount, setMayorCount] = useState(0);
	const [isChecked, setIsChecked] = useState(false);
	const [selectedVehiculo, setSelectedVehiculo] = useState('');
	const [cantVehiculo, setCantVehiculo] = useState(0);	

	// Configuración de días permitidos por ruta
	const allowedDaysByRoute = {
		'P~PIC-P~TPB': [1, 2, 3, 4, 5, 6], // Lunes a Sábado
		'P~TPB-P~PIC': [0, 1, 2, 3, 4, 5], // Domingo a Viernes
		'P~PIC-P~MZT': [2, 4, 6], // Martes, Jueves y Sábado
		'P~MZT-P~PIC': [0, 3, 5], // Domingo, Miércoles y Viernes
	};

	const fechaToday = new Date();
	/*console.log(fechaToday.toLocaleDateString());*/
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
	  setIsFocused(true);
	};

	const onChange = (date, dateString) => {
		console.log(date, dateString);
		console.log("Date: ", date);
		console.log("Datestring: ", dateString);
	
		setFormData({
			...formData,
			fechaIda: dateString,			
		});	
	
	};	

	//FormData
	const [formData, setFormData] = useState({
		origen: '',
		destino: '',
		travelType: 'RETURN', // Establece el valor predeterminado a "RETURN"
		fechaIda: '',
		fechaVuelta: '',
	});

	const [selectedTravelType, setSelectedTravelType] = useState('RETURN'); // Nuevo estado
	const [mostrarCamposFecha, setMostrarCamposFecha] = useState(false);
	const [mostrarDatepicker, setMostrarDatepicker] = useState(false);

	const handleChangeReservation = e => {		
		const name = e.target.name;
		const value = e.target.value;

		if (name === 'travelType') {
			setSelectedTravelType(value);
		}

		//console.log(e.target.name, e.target.value);
		setFormData({...formData,[name]: value});
	};
	const displayDate = e => {		
		setMostrarDatepicker(!mostrarDatepicker);
	}

	const handleBoxAuto = () => {
		setBoxAuto(!boxAuto);
	}

	const handleBoxPasajero = () => {
		setBoxPasajero(!boxPasajero);
	}


  	// Función para manejar el cambio del checkbox
	const handleChangeAuto = (event) => {
		setIsChecked(event.target.checked);
	};
	// Manejadores para sumar
	const handleAdultoCount = () => {
		setAdultoCount(adultoCount + 1);		
	};
	const handleInfanteCount = () => {
		setInfanteCount(infanteCount + 1);		
	};
	const handleMenorCount = () => {
		setMenorCount(menorCount + 1);		
	};
	const handleMayorCount = () => {
		setMayorCount(mayorCount + 1);		
	};

	// Manejadores para restar
    const handleAdultoCountDecrement = () => {
        setAdultoCount(adultoCount > 0 ? adultoCount - 1 : 0);
    };

    const handleInfanteCountDecrement = () => {
        setInfanteCount(infanteCount > 0 ? infanteCount - 1 : 0);
    };

    const handleMenorCountDecrement = () => {
        setMenorCount(menorCount > 0 ? menorCount - 1 : 0);
    };

    const handleMayorCountDecrement = () => {
        setMayorCount(mayorCount > 0 ? mayorCount - 1 : 0);
    };

	// Suma de los clics de ambos botones
	const totalClicks = adultoCount + infanteCount + menorCount + mayorCount;

	const handleDateChange = (date, dateString, type) => {
		/*console.log('Fecha seleccionada: ', dateString);*/
		setFormData({
		  ...formData,
		  [type]: dateString,
		});
	};
	
	const handleDateChangeReturn = (dates, dateStrings) => {
		const [fechaIda, fechaVuelta] = dateStrings;
		/*console.log('Fechas seleccionadas:', dateStrings);*/
		setFormData({
		  ...formData,
		  fechaIda,
		  fechaVuelta,
		});
	};	


	// Vehiculo seleccionado 
	const handleOptionVehiculo = (option) =>{
		setSelectedVehiculo(option);

		if(option === ''){
			setCantVehiculo(0);
		}else{
			setCantVehiculo(1);
		}
		
		/*console.log('Opción seleccionada:', selectedVehiculo);*/
		
	}


	// Intercambia los valores de origen y destino
	const handleIntercambiar = () => {		
		setFormData({
			...formData,
			origen: formData.destino,
			destino: formData.origen ,
		});				
	};

	

	useEffect(() => {
		if (formData.origen && formData.destino) {
		  setMostrarCamposFecha(true);
		} else {
		  setMostrarCamposFecha(false);
		}
	}, [formData.origen, formData.destino]);

	const opcionesRuta = [
	
		{ value: 'P~PIC', label: 'La Paz' },
		{ value: 'P~MZT', label: 'Mazatlán' },
		{ value: 'P~TPB', label: 'Topolobampo' },
		// Agrega más opciones de destino según sea necesario
	];

	const rutasNoPermitidas = {
		'P~MZT': ['P~TPB'],
		'P~TPB': ['P~MZT'],
	  };
		
	  const getOpcionesFiltradas = (opciones, seleccionado, noPermitidos) => {
		if (rutasNoPermitidas[seleccionado]) {
		  return opciones.filter((opcion) => !noPermitidos.includes(opcion.value) && opcion.value !== seleccionado);
		}
		return opciones.filter((opcion) => opcion.value !== seleccionado);
	  };



	  //const allowedDays = getAllowedDays(formData.origen, formData.destino);


	  
	const getAllowedDays = (origen, destino) => {
		const route = `${origen}-${destino}`;
		return allowedDaysByRoute[route] || [];
	};

	  const disabledDate = (current, allowedDays) => {
		// Can not select days before today and today
		if (!current) return false;
		const day = current.day();
		const isBeforeToday = current && current < dayjs().startOf('day');
		const isAllowedDay = allowedDays.includes(day);
		return isBeforeToday || !isAllowedDay;
	  };

	
	const reservation = (e) => {
		
		e.preventDefault();		
		/*console.log("formData: ", formData);*/
				
		if (selectedTravelType === 'RETURN') {

			// Acciones específicas para el tipo de viaje de "RETURN"
			window.open(
				`https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&
				cw_journeysearch_j1_passengers[0][ctg]=ADL&
				cw_journeysearch_j1_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j1_passengers[1][ctg]=CHD&
				cw_journeysearch_j1_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j1_passengers[2][ctg]=INF&
				cw_journeysearch_j1_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j1_passengers[3][ctg]=RET&
				cw_journeysearch_j1_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j1_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j1_vehicles[0][qty]=1&	
				cw_journeysearch_j2_passengers[0][ctg]=ADL&
				cw_journeysearch_j2_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j2_passengers[1][ctg]=CHD&
				cw_journeysearch_j2_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j2_passengers[2][ctg]=INF&
				cw_journeysearch_j2_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j2_passengers[3][ctg]=RET&
				cw_journeysearch_j2_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j2_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j2_vehicles[0][qty]=1&	
				bookingFlow=RETURN&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&
				cw_journeysearch_j1_route=${formData.origen}-${formData.destino}&
				cw_journeysearch_j2_date=${formData.fechaVuelta}T00:00:00&
				cw_journeysearch_j2_route=${formData.destino}-${formData.origen }
				`,'_blank'
			);

		} else if (selectedTravelType === 'ONEWAY') {

			// Acciones específicas para el tipo de viaje de "ONEWAY"

			/*window.open(
				`https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&
				cw_journeysearch_j1_passengers[0][ctg]=ADL&
				cw_journeysearch_j1_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j1_passengers[1][ctg]=CHD&
				cw_journeysearch_j1_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j1_passengers[2][ctg]=INF&
				cw_journeysearch_j1_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j1_passengers[3][ctg]=RET&
				cw_journeysearch_j1_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j1_passengers[3][ctg]=PET&
				cw_journeysearch_j1_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j1_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j1_vehicles[0][qty]=1&				
				bookingFlow=ONEWAY&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&cw_journeysearch_j1_route=${formData.origen}-${formData.destino}`,'_blank'					
			);
			*/
			window.open(
				`https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&
				cw_journeysearch_j1_passengers[0][ctg]=ADL&
				cw_journeysearch_j1_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j1_passengers[1][ctg]=CHD&
				cw_journeysearch_j1_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j1_passengers[2][ctg]=INF&
				cw_journeysearch_j1_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j1_passengers[3][ctg]=RET&
				cw_journeysearch_j1_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j1_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j1_vehicles[0][qty]=1&				
				bookingFlow=ONEWAY&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&cw_journeysearch_j1_route=${formData.origen}-${formData.destino}`,'_blank'					
			);
		}		
				
	};

	
  return (
	<form className='formBooking' onSubmit={reservation}> 
		<div className='formBooking_container'>
			{/* Radio buttons */}
			<div className='radioBooking'>
				<div className='box_radio_booking'>
					<div className='radioButons'>
						<input type="radio" name="travelType" value="RETURN"   checked={selectedTravelType === 'RETURN'} onChange={handleChangeReservation} />
						<label>{t('text1')}</label>
					</div>
					
					<div className='radioButons'>
						<input type="radio" name="travelType" value="ONEWAY" onChange={handleChangeReservation}/>
						<label>{t('text2')}</label>
					</div>
				</div>	

				<div className='radioButons checkAuto'>					
					<label>
						<input type="checkbox" checked={isChecked} onChange={handleChangeAuto} />
						{t('text9')}
					</label>
				</div>
			</div>

			{/* Selects Origen  */}

			<div className='contentBooking'>
				<div className={`box_formOrigen  ${isFocused ? 'focus' : ''}`} onFocus={handleFocus}>
					<label>{t('text10')}:</label>
					<select name="origen" value={formData.origen} onChange={handleChangeReservation}>
					<option value=""></option>
					{getOpcionesFiltradas(opcionesRuta, formData.destino, rutasNoPermitidas[formData.destino] || []).map((opcion) => (
						<option key={opcion.value} value={opcion.value}>
						{opcion.label}
						</option>
					))}
					</select>				
				</div>
				{/* Botón para intercambiar valores entre Origen y Destino */}
				<div className='box_formButton'>
					<button type="button"  onClick={handleIntercambiar}><i class="fi fi-rr-exchange-alt"></i></button>
				</div>
				{/* Selects Destino */}
				<div className={`box_formDestino ${isFocused ? 'focus' : ''}`} onFocus={handleFocus}>
					<label>{t('text11')}:</label>

					<select name="destino" value={formData.destino} onChange={handleChangeReservation}>
					<option value=""></option>
					{getOpcionesFiltradas(opcionesRuta, formData.origen, rutasNoPermitidas[formData.origen] || []).map((opcion) => (
						<option key={opcion.value} value={opcion.value}>
						{opcion.label}
						</option>
					))}
					</select>		
				</div>  		
			</div>	

			{/* Inputs Fecha */}
			{mostrarCamposFecha && formData.travelType ==='RETURN' && (
			<div className='contentBooking'>
				<div className={`box_fechaSoloIda ${isFocused ? 'focus' : ''}`} onFocus={handleFocus}>
					<label> {t('text12')}:</label>					
					{/* <input type="date" name="fechaIda" min={getTodayDate()} onChange={handleChangeReservation}  /> */}
					{/*<ConfigProvider locale={es_ES}>  
						<Space direction="vertical" size={24} className="custom-datepicker">
							<RangePicker disabledDate={disabledDate}  onChange={handleDateChangeReturn}/>						
						</Space>
					</ConfigProvider>	*/}
					<div style={{display:'flex'}}>
                <ConfigProvider locale={es_ES}>
                  <Space direction="vertical" size={24} className="custom-datepicker">
                    <DatePicker
                      disabledDate={(current) => disabledDate(current, getAllowedDays(formData.origen, formData.destino))}
                      name="fechaIda"
                      onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaIda')} />
                  </Space>
                </ConfigProvider>
                <ConfigProvider locale={es_ES}>
                  <Space direction="vertical" size={24} className="custom-datepicker">
                    <DatePicker
                      disabledDate={(current) => disabledDate(current, getAllowedDays(formData.destino, formData.origen))}
                      name="fechaVuelta"
                      onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaVuelta')}
                    />
                  </Space>
                </ConfigProvider>
              </div>
				</div>

			</div>
			)}

			{mostrarCamposFecha && formData.travelType === 'ONEWAY' && (
			<div className='contentBooking'>
				<div className='box_fechaSoloIda'>
					<label>{t('')}Salida</label> 							
					
					{/*<ConfigProvider locale={es_ES}>  
						<Space direction="vertical" size={24} className="custom-datepicker">
							<DatePicker disabledDate={disabledDate} name="fechaIda"  onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaIda')}/>						
						</Space>					
					</ConfigProvider>*/}
						
					  <ConfigProvider locale={es_ES}>  
						<Space direction="vertical" size={24} className="custom-datepicker">
						<DatePicker
							disabledDate={(current) => disabledDate(current, getAllowedDays(formData.origen, formData.destino))}
							name="fechaIda"
							onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaIda')}
						/>            
						</Space>          
					</ConfigProvider>
							
				</div>								 					
			</div>       

			)}
	
			{mostrarCamposFecha && (
				<>
				<div className='contentBooking'>
					<div className={`box_container_pasajero ${isChecked ? 'resized' : ''}`}>
						<div className={`container_cant_pasajero ${isChecked ? 'resized' : ''}`} onClick={handleBoxPasajero}>
							<label>{t('text28')}:</label> 
							<label>{totalClicks} <i class="fi fi-rr-angle-small-down"></i> </label> 							
						</div>	
						
					</div>
					{isChecked && (
						<>
					<div className={`box_container_auto ${isChecked ? 'resized' : ''}`}>
						<div className='container_auto' onClick={handleBoxAuto}>
							<div>
								<label>{t('text19')}:</label> 
								{/*<label>{selectedVehiculo}</label>*/}
							</div>
							
							<label>{cantVehiculo} <i class="fi fi-rr-angle-small-down"></i> </label> 
						</div>	
																									
					</div>	
					

					</>)}
				</div>
				<div className=''>
						{boxPasajero && (
						<div className='modalPasajero'>
							<div className='content_cant_pasajero'>
								<div className='box_cant_pasajero'>
									<label>{t('text25')}:</label>
									<label>{t('text13')}</label>
								</div>
								<div className='box_cant_pasajero'>
									<label> {adultoCount} </label>
									<div onClick={handleAdultoCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
									<div  onClick={handleAdultoCount}><i class="fi fi-sr-square-plus"></i></div>
								</div>														
							</div>
							<div className='content_cant_pasajero'>
								<div className='box_cant_pasajero'>
									<label>{t('text14')}:</label>
									<label>{t('text15')}</label>
								</div>
								<div className='box_cant_pasajero'>
									<label> {infanteCount} </label>
									<div onClick={handleInfanteCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
									<div  onClick={handleInfanteCount}><i class="fi fi-sr-square-plus"></i></div>
								</div>
							</div>
							<div className='content_cant_pasajero'>
								<div className='box_cant_pasajero'>
									<label>{t('text26')}:</label>
									<label>{t('text16')}</label>
								</div>
								<div className='box_cant_pasajero'>
									<label> {menorCount} </label>
									<div onClick={handleMenorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
									<div  onClick={handleMenorCount}><i class="fi fi-sr-square-plus"></i></div>
								</div>														
							</div>
							<div className='content_cant_pasajero'>
								<div className='box_cant_pasajero'>
									<label>{t('text17')}:</label>
									<label>{t('text18')}</label>
								</div>
								<div className='box_cant_pasajero'>
									<label> {mayorCount} </label>
									<div onClick={handleMayorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
									<div  onClick={handleMayorCount}><i class="fi fi-sr-square-plus"></i></div>
								</div>
							</div>
							<button onClick={handleBoxPasajero}>{t('text27')}</button>
						</div>
						)}
				</div>
				<div className=''>
				{boxAuto && (						
						<div className='modalAuto'>
							<div className='content_auto' onClick={() => handleOptionVehiculo('')}>
								<div className='box_auto'><img src={sinVehiculo} alt='' /> </div>
								<label>{t('')}Sin vehículo</label>
							</div>
							<div className='content_auto' onClick={() => handleOptionVehiculo('MC')}>								
								<div className='box_auto'><img src={motocicleta} alt='' /> </div>
								<label>{t('text21')}</label>																								
							</div>
							<div className='content_auto' onClick={() => handleOptionVehiculo('CAR')}>								
								<div className='box_auto'><img src={vehiculo} alt='' /> </div>
								<label>{t('text22')}</label>								
							</div>
							<div className='content_auto' onClick={() => handleOptionVehiculo('MHO')}>								
								<div className='box_auto'><img src={motorhome} alt='' /> </div>
								<label>{t('text23')}</label>														
							</div>
							<button onClick={handleBoxAuto}>{t('text27')}</button>
						</div>
						)}
				</div>
				</>
			)}					  

			<div className='contentBooking btnBuscar'>
				<button type='submit' disabled={!formData.origen && !formData.destino} className='btnBuscarViaje'>  
					<div className='textPlaceholder'>
						<span class="textBuscar">{t('text24')} </span> 
						<span class="icon"><i class="fi fi-sr-angle-circle-right" style={{display:'flex', alignItems:'center', fontSize:'20px'}}></i></span> 
						{/* <i class="fi fi-rr-arrow-circle-right"></i> */}
					</div>	
				</button>																															
			</div> 
		</div>
	</form>

  );
};

function getTodayDate() {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
	const day = String(today.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
  }

export default Reserva;





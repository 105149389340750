import React, {useState} from 'react'
import Navbar from '../components/Navbar';
import { Outlet, Link } from "react-router-dom";
import '../css/tarifa_carga.css';
import FooterCarga from '../components/FooterCarga';
import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import qrCode from '../assets/images/tarifas/qrCode.png'
import { useTranslation } from 'react-i18next';
export default function TarifasCarga() {
    const { t } = useTranslation('tarifasCarga');
    
    const [selectElegirRuta, setSelectElegirRuta] = useState(false);
    const [nameOption, setNameOption] = useState('La Paz - Topolobampo');

    const chooseRutasVisible = () => {
        setSelectElegirRuta(!selectElegirRuta);
    };

    const rutaSelected = (option) => {
        setNameOption(option);            
        setSelectElegirRuta(!selectElegirRuta);
    };

  return (
    <>
    <Navbar />
    <section>
        <div className='header_section carga'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('tarifas')}</h3>
                    <p>{t('tarifas_p')}</p>
                </div>                
            </div>
        </div>
    </section>

    <section className='tarifario carga'>
        <div className='select_tarifario'>
            <div className='container'>
                <div className='select_route_carga'>           
                    <label>{t('text1')}</label>
                    <div className='box_select_route' onClick={chooseRutasVisible} >                    
                                             
                            <div className='select_ruta_carga'>                               
                                <label>{nameOption} </label>
                                <i class="fi fi-br-angle-small-down"></i>
                            </div>                                                      
                        <div>
                            {selectElegirRuta && 
                            <div className='optionListRoutes'>
                                <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                                <div className='optionRoutes' onClick={() => rutaSelected('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                <div className='optionRoutes' onClick={() => rutaSelected('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                            </div>
                            }
                        </div>
                    </div>      
                </div>
            </div>
        </div>
      

        <div className='container'>
            <div className='tarifario_content_box'>                                              
                <div className='tabla-tarifa carga'>
                     
                {nameOption === 'La Paz - Topolobampo' || nameOption === 'Topolobampo - La Paz' ? (
                <>
                    <table>
                        <tbody>
                            <tr>
                                <th>{t('text2')}</th>
                                <th>{t('text3')}</th>
                            </tr>
                            <tr>
                                <td>{t('text4')} <br /> <p>{t('text17')}</p></td>
                                <td>$10,950</td>
                            </tr>
                            <tr>
                                <td>{t('text5')} <br /> <p>{t('text17')}</p></td>
                                <td>$10,200</td>
                            </tr>
                            <tr>
                                <td>{t('text6')} <br /> <p>{t('text18')}</p></td>
                                <td>$14,500</td>
                            </tr>
                            <tr>
                                <td>{t('text7')} <br /> <p>{t('text19')}</p></td>
                                <td>$17,500</td>
                            </tr>
                            <tr>
                                <td> {t('text8')}<br /> <p>{t('text19')}</p></td>
                                <td>$17,500</td>
                            </tr>
                            <tr>
                                <td>{t('text9')} <br /> <p>{t('text20')} <br /> {t('text33')}</p></td>
                                <td>*$20,550</td>
                            </tr>
                            <tr>
                                <td>{t('text11')} <br /> <p>{t('text21')} <br /> {t('text33')}</p></td>
                                <td>*$22,390</td>
                            </tr>
                            <tr>
                                <td>{t('text12')} <br /> <p>{t('text22')}H</p></td>
                                <td>$29,350</td>
                            </tr>
                            <tr>
                                <td>{t('text13')} <br /> <p>{t('text23')}</p></td>
                                <td>$38,150</td>
                            </tr>
                            <tr>
                                <td>{t('text14')} <br /> <p>{t('text24')}.</p></td>
                                <td>$43,580</td>
                            </tr>
                            <tr>
                                <td>{t('text15')}  <br /> <p>{t('text25')}</p></td>
                                <td>$1,460</td>
                            </tr>
                            <tr>
                                <td>{t('text16')} <br />  <p></p></td>
                                <td>$2,150</td>
                            </tr>

                        </tbody>
                    </table>  
                    <ul>
                        <li>* {t('text26')}</li>
                        <li>* {t('text27')}</li>
                    </ul>
                </>
                ): (
                <>
                    <table>
                        <tbody>
                            <tr>
                                <th>{t('text2')}</th>
                                <th>{t('text3')}</th>
                            </tr>
                            <tr>
                                <td>{t('text4')} <br /> <p>{t('text17')}</p></td>
                                <td>$14,750</td>
                            </tr>
                            <tr>
                                <td>{t('text5')} <br /> <p>{t('text17')}</p></td>
                                <td>$14,000</td>
                            </tr>
                            <tr>
                                <td>{t('text6')} <br /> <p>{t('text18')} </p></td>
                                <td>$19,900</td>
                            </tr>
                            <tr>
                                <td>{t('text7')} <br /> <p>{t('text19')} </p></td>
                                <td>$23,300</td>
                            </tr>
                            <tr>
                                <td> {t('text8')}<br /> <p>{t('text19')} </p></td>
                                <td>$23,300</td>
                            </tr>
                            <tr>
                                <td>{t('text9')} <br /> <p>{t('text20')} <br /> {t('text33')} </p></td>
                                <td>*$26,070</td>
                            </tr>
                            <tr>
                                <td>{t('text11')}<br /> <p>{t('text21')} <br />{t('text33')}  </p></td>
                                <td>*$29,320</td>
                            </tr>
                            <tr>
                                <td>{t('text12')}<br /> <p>{t('text22')}</p></td>
                                <td>$38,500</td>
                            </tr>
                            <tr>
                                <td>{t('text13')} <br /> <p>{t('text23')}</p></td>
                                <td>$49,150</td>
                            </tr>
                            <tr>
                                <td>{t('text14')} <br /> <p>{t('text24')}</p></td>
                                <td>$57,100</td>
                            </tr>
                            <tr>
                                <td>{t('text15')}  <br /> <p>{t('text25')} </p></td>
                                <td>$1,650</td>
                            </tr>
                            <tr>
                                <td>{t('text16')}  <br />  <p></p></td>
                                <td>$2,300</td>
                            </tr>
                        </tbody>
                    </table>  
                    <ul>
                        <li>• {t('text26')}</li>
                        <li>• {t('text27')}</li>
                    </ul> 
                </>
                )}                                                       
                </div>                                   
                <div className='form_contact_tarifas carga'>
                    <div className='zoho_form_box_content'>
                        <div className='text_box_content_carga'>
                            <h3>{t('')}Cotiza ahora</h3>
                            <p>{t('')}Completa el formulario de contacto a continuación y cuéntanos lo que necesitas.</p>
                        </div>                        
                        <div className='btn_box_content_carga'>
                        <a href="mailto:contacto.comercial@bajaferries.com.mx" target="_blank" rel="noopener noreferrer">{t('')}Contactar</a>  
                                                          
                        </div>                                                                         
                    
                    </div>     
                    <div className='qr_box_content'>
                        <div>
                            <img src={qrCode} alt='Descarga tarifas' />
                        </div>
                        <div className=''>                            
                            <p>{t('')}Descarga el tarifario para conocer todos nuestros precios y rutas.</p>
                        </div> 
                        
                        <div className='btn_box_content_carga'>
                            <a href={qrCode} download="tarifas.png">
                                <label>{t('')}Descargar ahora</label>
                                <label><i class="fi fi-rr-cloud-download-alt"></i>  </label>
                            </a>                             
                        </div>

                    </div>              
                </div> 
            </div>     
        </div>       
    </section>
        
    <section className="callToAction carga">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta rutas y horarios</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas-horario-carga">Ver ahora</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />
    <FooterCarga />
    </>
  )
}


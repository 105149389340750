import React, {useState, useEffect} from 'react'
import '../../css/valoracion.css'
import logoHorizontal from '../../assets/images/logosKit/media-kit-logo-hor.png'

export default function ValoracionForm() {
    const [isModalVisible, setIsModalVisible] = useState(false); // Estado para manejar la visivilidad del modal
    const [valoracion, setValoracion] = useState(null);
    const [comentario, setComentario] = useState(null);
    const [showOnScroll, setShowOnScroll] = useState(false);
    const [alert, setAlert] = useState(false);
      

    const handleModal = () =>{
        setIsModalVisible(true)
    }

    const handleRatingChange = (rating) => {
        setValoracion(rating);  
       
    };

    const closeModal = () => {
        setIsModalVisible(false); // Hide the modal when needed
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > (window.innerHeight * 0.6)) {
                setShowOnScroll(true);
            } else {
                setShowOnScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();                
        console.log('Valor seleccionado:', valoracion, comentario);
        const fecha_valoracion = new Date().toISOString().split('T')[0];  

        console.log('Fecha_valoracion:', fecha_valoracion); 
        
        try {
            //await axios.post('http://localhost:3004/newsletter', {
            //await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/enviar-correo', {
            await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/valoracion',{
            //await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/newsletter',{
                method:'POST',
                body: JSON.stringify({valoracion, comentario, fecha_valoracion}),
                headers: {'Content-Type': 'application/json'},
            });
    
            // Limpiar campos después de enviar el correo
            setValoracion(null);
            setComentario('');  // Reiniciar el comentario a una cadena vacía

            setAlert(true);
            console.log('Formulario enviado con éxito');
            
        } catch (error) {
            console.error('Error al enviar el correo:', error);
        }
    }
  

  return (
        <>
          {showOnScroll && (
                <section className='valoracion'>
                    <div className='box_valoracion' onClick={handleModal}>
                        Valoración
                    </div>
                </section>
            )}           
            
            {isModalVisible && (
                <div className="modal_valoracion">
                    <div className="modal-content">
                        <div className='cerrarbtn_modal'>
                            <button onClick={closeModal}><i class="fi fi-rr-cross-circle"></i></button>
                        </div>
                        <div className='modal_box'>
                            <div className='logoModal'>
                                <img src={logoHorizontal} alt='logo baja ferries horizontal'/>
                            </div>
                            <div >
                                <div className='contentbox_contactform'>
                                    <div className='box_valoracionform'>
                                        <div className='boxes_valoracionform'>
                                            <label>Califica nuestra página  </label>
                                            <div className='calificacion'>
                                                <div className='emoji'>
                                                    <button  onClick={() => handleRatingChange('1')}><i className="fi fi-rs-angry"></i></button>                                                    
                                                </div>
                                                <div className='emoji' >
                                                    <button onClick={() => handleRatingChange('2')}><i className="fi fi-rs-face-confused"></i></button>                                                    
                                                </div>
                                                <div className='emoji' >
                                                    <button onClick={() => handleRatingChange('3')}><i className="fi fi-rr-meh"></i></button>                                                    
                                                </div>
                                                <div className='emoji' >
                                                    <button onClick={() => handleRatingChange('4')}> <i className="fi fi-rr-face-relieved"></i></button>                                                   
                                                </div>
                                                <div className='emoji' >
                                                    <button onClick={() => handleRatingChange('5')}><i className="fi fi-rr-face-awesome"></i></button>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='box_valoracionform'>
                                        <div className='boxes_valoracionform'>
                                            <label>Queremos saber tu opinion  </label>
                                            <textarea rows={3} cols={30} name='mensaje'   value={comentario} onChange={(e) => setComentario(e.target.value)}>  </textarea>
                                        </div>
                                    </div>
                                   
                                    <div className='box_valoracionform'>
                                        <div className='boxes_valoracionform'>
                                            <button className='btn_valorar' disabled={!valoracion} onClick={handleSubmit}>Enviar</button>                                    
                                        </div>                                                                
                                    </div>                                
                                </div>                                                      
                            </div>                                         
                        </div>
                    </div>
                </div>
            )}
        </>
  )
}



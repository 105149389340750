import React from 'react'
import '../css/aviso_privacidad.css'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next';


export default function Aviso_privacidad() {
    const { t } = useTranslation('aviso_privacidad');
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>{t('aviso_privacidad')} </h3>
                        <p></p>
                    </div>                
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className='aviso_privacidad'>
                    <h3>{t('texto1')}</h3>
                    <p><strong>1. {t('texto2')}</strong> </p>
                    <p>{t('texto3')}.</p>
                    <p><strong>2. {t('texto4')}</strong></p>                    
                    <h3>{t('texto5')}</h3>
                    <p><strong>{t('texto6')}</strong></p>
                    <p><strong>{t('texto7')},</strong> {t('texto8')}</p>
                    <p><strong>{t('texto9')}</strong></p>
                    <p>{t('texto10')}</p>
                    <p><strong>{t('texto11')}</strong></p>
                    <p>{t('texto12')}</p>
                    <p><strong>{t('texto13')}</strong></p>
                    <p>{t('texto14')}</p>
                    <p>{t('texto15')}</p>
                    <p>{t('texto16')}</p>
                    <p>{t('texto17')}</p>
                    <p>{t('texto18')}</p>
                    <p><strong>{t('texto19')}</strong></p>
                    <p>{t('texto20')}</p>
                    <p><strong>{t('texto21')}</strong></p>
                    <p>{t('texto22')}</p>
                    <p><strong>{t('texto23')}</strong></p>
                    <p>{t('texto24')}</p>
                    <p><strong>{t('texto25')}</strong></p>
                    <p>{t('texto26')}</p>
                    <p>{t('texto27')}</p>
                    <p>{t('texto28')}</p>
                    <p>{t('texto29')}</p>
                    <p>{t('texto30')}</p>
                    <p>{t('texto31')}</p>
                    <p>{t('texto32')}</p>
                    <p><strong>{t('texto33')}</strong></p>
                    <p>{t('texto34')}</p>
                    <p>{t('texto35')}</p>
                    <p><strong>{t('texto36')}</strong></p>
                    <p>{t('texto37')}</p>
                    <p><strong>{t('texto38')}</strong></p>
                    <p>{t('texto39')}</p>
                    <p>{t('texto40')}</p>
                    <p><strong>{t('texto41')}</strong></p>
                    <p>{t('texto42')}</p>
                    <p><strong>{t('texto43')}</strong></p>
                    <p>{t('texto44')}</p>
                    <p>{t('texto45')}</p>
                    <p>{t('texto46')}_____________________________________ {t('texto47')} ______________________.</p>
                    <p><strong>{t('texto48')}</strong></p>
                    <p>{t('texto49')}</p>
                    <p>{t('texto50')}</p>
                    
                </div>
            </div>
        </section>
        <Footer />
    
    </>
  )
}

import React from 'react'
import '../css/condiciones.css'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next';
export default function Condiciones() {
    const { t } = useTranslation('condiciones');
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>{t('condiciones')} </h3>
                        <p></p>
                    </div>                
                </div>
            </div>
        </section>
    
    <section className='condiciones_servicios'>
        <div className='container'>
            <div>
                <h3>1.{t('text1')}</h3>
                <p>{t('text2')}</p>
            </div>
            <div>
                <h3>2.{t('text3')}</h3>
                <p>{t('text4')}</p>
                <p>{t('text5')}</p>
                <p>{t('text6')}</p>

                <p>{t('text7')}</p>
                <p><strong>1.{t('text8')}.</strong> {t('text9')}.</p>

                <p>{t('text10')}</p>

                <p><strong>{t('text11')}: </strong> {t('text12')}</p>

                <p>{t('text13')}</p>
                <p>{t('text14')}</p>

                <p>{t('text15')}</p>

                <p><strong>{t('text16')}: </strong>{t('text17')} </p>
                <p><strong>2.{t('text18')}: {t('la_empresa')} </strong> {t('text19')} </p>

                <p>{t('text20')}</p>

                <p>{t('text21')} <strong>{t('la_empresa')}</strong> {t('text22')}.</p>

                <p>{t('text24')}</p>

                <p>{t('text25')}  <strong>{t('la_empresa')}</strong>{t('text26')} </p>

                <p>{t('text27')}</p>

                <p>{t('text28')} <strong>{t('la_empresa')}</strong>.</p>

                <p>{t('text29')}</p>

                <p>{t('text30')}</p>

                <p>i) <strong>{t('la_empresa')}</strong>, {t('text31')}<strong> {t('la_empresa')}</strong> {t('text32')}</p>
                
                <p>{t('text33')} </p>
                <p>{t('text34')} <strong>{t('text35')}</strong>{t('text36')}  </p>

                <p>3. <strong>{t('text37')}</strong> {t('text38')}: </p>

                <p>I.- {t('text39')} </p>

                <p>II.- {t('text40')}</p>

                <p>{t('text41')}<strong> {t('la_empresa')}</strong> {t('text42')} <strong>{t('cliente')}</strong>, {t('text43')}:</p>

                <p>I.- {t('text44')}</p>
                <p>II.- {t('text45')} </p>

                <p>{t('text46')}</p>

                <p>{t('text47')}</p>

                <p><strong>4. {t('text48')}:</strong> {t('text49')}</p>

                <p>{t('text50')} </p>

                <p><strong>5. {t('text51')}</strong>, {t('text52')}:</p>

                <p>{t('text53')}a) .</p>

                <p>{t('text54')}b) </p>

                <p>{t('text55')} <strong>{t('la_empresa')}</strong> {t('text56')}</p>

                <p>{t('text57')}d) . </p>

                <p>6. <strong>{t('la_empresa')}</strong> {t('text58')}. </p>

                <p><strong> {t('text59')}</strong> {t('text60')} <strong>{t('la_empresa')}</strong>. <strong>{t('la_empresa')}</strong> {t('text61')}  </p>

                <p>{t('text62')}</p>

                <p><strong>8. {t('text63')}.</strong></p>
                <p><strong>{t('text64')}A) :</strong> {t('text65')}, <strong>{t('cliente')}</strong> {t('text66')} <strong>{t('pasajero')}</strong>  {t('text67')}: </p>

                <p><strong>{t('text68')} </strong> {t('text69')}: </p>

                <p><strong>i </strong>{t('text70')} </p>

                <p><strong>ii </strong> {t('text71')}</p>

                <p><strong>iii </strong>{t('text72')} </p>

                <p>{t('text73')}</p>

                <p>{t('text74')}</p>

                <p>{t('text75')}</p>

                <p>{t('text76')}</p>

                <p>{t('text77')}</p>

                <p>{t('text78')}</p>

                <p>{t('text79')}</p>

                <p>{t('text80')}</p>
                <p>{t('text81')}</p>
                <p>{t('text82')}</p>
                <p>{t('text83')}</p>

                <p>{t('text84')}</p>

                <p>{t('text85')}</p>

                <p>{t('text86')}</p>

                <p><strong>{t('text87')}:</strong> {t('text88')}</p>

                <p>{t('text89')} <strong>{t('pasajero')}</strong> {t('text90')} </p>

                <p>{t('text91')} </p>
                <p>{t('text92')}</p>

                <p><strong>{t('text93')}</strong>{t('text94')}.- <strong>{t('la_empresa')}</strong> {t('text95')} </p>

                <p>9. <strong>{t('text96')}{t('pasajero')}</strong> {t('text97')}: </p>

                <p>{t('text98')}</p>
                <p>{t('text99')}</p>
                <p>{t('text100')}</p> 
                <p>{t('text101')} </p>

                <p><strong>10. {t('text102')}</strong> {t('text103')} <strong>{t('la_empresa')}</strong> {t('text105')} </p>

                <p><strong>{t('la_empresa')}</strong> {t('text105')}  <strong>{t('la_empresa')}</strong>{t('text106')} <strong>{t('pasajero')}</strong> {t('text107')} </p>
                <p>{t('text108')} <strong>{t('pasajero')}</strong> {t('text109')}</p>

                <p><strong>{t('text110')}:</strong>{t('text111')} </p>

                <p><strong>{t('text112')}:</strong>{t('text113')}</p>

                <p><strong>{t('text114')}:</strong></p>
                <p><strong>{t('text115')}:</strong> {t('text116')}  </p>

                <p><strong>{t('text117')}:</strong> {t('text118')} </p>

                <p><strong>{t('text119')}:</strong> {t('text120')}. </p>

                <p><strong>{t('text121')}:</strong> {t('text122')}. </p>
                <p><strong>{t('text123')}</strong> {t('text124')}. </p>

                <p><strong>11. {t('text125')}. </strong> <strong>{t('la_empresa')} </strong>{t('text126')} </p>

                <p>{t('text127')} </p>

                <p><strong>12. {t('text128')}.</strong> {t('text129')} <strong> {t('la_empresa')}</strong>{t('text130')}</p>

                <p><strong>13. {t('text131')}</strong>{t('text132')} <strong> {t('la_empresa')}</strong>, {t('texto2')} <strong> {t('la_empresa')}</strong>.</p>

                <p><strong>14. {t('text133')}</strong> {t('text134')}  </p>

                <p><strong>15. {t('text135')} .</strong> {t('text136')} </p>

                <p><strong>16. {t('text137')}.</strong> {t('text138')}<strong> {t('la_empresa')}</strong>,{t('text139')}  </p>

                <p><strong>17. {t('text140')}.</strong> {t('text141')} </p>

                <p><strong>18. {t('text142')}.</strong>  {t('text143')} <strong> {t('la_empresa')} </strong>{t('text144')} </p>

                <p><strong>19. {t('text145')}</strong>{t('text146')}</p>
            </div>

        </div>
    </section>
    <Footer />
    </>
  )
}


import React, {useState} from 'react'
import axios from 'axios';
import '../css/viaje_en_grupo.css'
import viajeEnGrupo from '../assets/images/viajeGrupo/viajeEnGrupo.webp';
import { Outlet, Link } from "react-router-dom";
import reservacion from '../assets/images/cta/reservacion.svg'
import rutas from '../assets/images/cta/rutasyhorarios.svg'
import destinos from '../assets/images/cta/exploraDestinos.svg'
import Footer from '../components/Footer';

import { useTranslation } from 'react-i18next';

export default function ViajeEnGrupo() {
    const { t } = useTranslation('viajeGrupo');


    const [adultoCount, setAdultoCount] = useState(1);
	const [infanteCount, setInfanteCount] = useState(0);
	const [menorCount, setMenorCount] = useState(0);
	const [mayorCount, setMayorCount] = useState(0);
    
    const [aceptoPoliticas, setAceptoPoliticas] = useState(false);
    const [nombre, setNombre] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [ruta, setRuta] = useState('');
    const [mensaje, setMensaje] = useState('');

    const [alert, setAlert] = useState(false);
    // Función para manejar el cambio del checkbox
    const handleCheckboxChange = (event) => {
        setAceptoPoliticas(event.target.checked);
    };

    // Manejadores para sumar
	const handleAdultoCount = () => {
		setAdultoCount(adultoCount + 1);		
	};
	const handleInfanteCount = () => {
		setInfanteCount(infanteCount + 1);		
	};
	const handleMenorCount = () => {
		setMenorCount(menorCount + 1);		
	};
	const handleMayorCount = () => {
		setMayorCount(mayorCount + 1);		
	};

	// Manejadores para restar
    const handleAdultoCountDecrement = () => {
        setAdultoCount(adultoCount > 0 ? adultoCount - 1 : 0);
    };

    const handleInfanteCountDecrement = () => {
        setInfanteCount(infanteCount > 0 ? infanteCount - 1 : 0);
    };

    const handleMenorCountDecrement = () => {
        setMenorCount(menorCount > 0 ? menorCount - 1 : 0);
    };

    const handleMayorCountDecrement = () => {
        setMayorCount(mayorCount > 0 ? mayorCount - 1 : 0);
    };

	// Suma de los clics de ambos botones
	//const totalClicks = adultoCount + infanteCount + menorCount + mayorCount;

    const enviarCorreo = async () => {
        console.log(nombre,                
            telefono,
            email,
            ruta,
            adultoCount,
            mayorCount,
            infanteCount,
            menorCount,
            mensaje);
        try {
            //await axios.post('http://localhost:3001/viaje-en-grupo', {
            await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/viaje-en-grupo', {
                nombre,                
                telefono,
                email,
                ruta,
                adultoCount,
                mayorCount,
                infanteCount,
                menorCount,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
    
            // Limpiar campos después de enviar el correo
            setNombre('');            
            setTelefono('');
            setEmail('');
            setRuta('');
            setAdultoCount('');
            setMayorCount('');
            setInfanteCount('');
            setMenorCount('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            //alert('Error al enviar el correo');
        }
    };
  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('viajeGrupo')}</h3>
                    <p>{t('viajeGrupo_p')}</p>
                </div>                
            </div>
        </div>
    </section>
    <section>        
        <div className='form_viaje_en_grupo'>
            <div className='container'>
                <div className='form_viaje_en_grupo_container'>
                   
                    <div className='form_viaje_en_grupo_content'>
                        <div className='form_viaje_en_grupo_img'><img src={viajeEnGrupo} alt='viaje en grupo - baja ferries'/></div>
                    </div>
                    <div className='form_viaje_en_grupo_content'>
                    <div className='form_viaje_en_grupo_content_div'>
                        <h2>{t('texto1')}</h2>
                        <p>{t('texto2')}</p>
                            {alert &&
                                <div className='alertMessage'>
                                    <label>{t('texto3')}</label>
                                    <p>{t('texto4')}</p>
                                </div>
                            }
                        
                            <div className='input-group'>
                                <input type='text' name='nombre' placeholder={t('placeholder1')} value={nombre} onChange={(e) => setNombre(e.target.value)} />
                                <input type='text' name='telefono' placeholder={t('placeholder7')} value={telefono} onChange={(e) => setTelefono(e.target.value)} />
                            </div>
                            <div className='input-group'>
                                <input type='email' name='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                <select value={ruta} onChange={(e) => setRuta(e.target.value)}>
                                    <option>{t('texto5')}</option>
                                    <option>La Paz-Topolobampo</option>
                                    <option>Topolobampo-La Paz</option>
                                    <option>La Paz-Mazatlán</option>
                                    <option>Mazatlán-La Paz</option>
                                </select>
                            </div>
                            <div className='input-group'>
                                <div className='content-input-group'>
                                    <div className='box-input-group'>
                                        <label>{t('texto6')}</label>
                                        <label>{t('texto7')}</label>
                                    </div>
                                    <div className='box-input-group'>
                                        <label>{adultoCount}</label>
                                        <div onClick={handleAdultoCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                        <div onClick={handleAdultoCount}><i class="fi fi-sr-square-plus"></i></div>                                        
                                    </div>
                                </div>
                                <div className='content-input-group'>
                                    <div className='box-input-group'>
                                        <label>{t('texto8')}</label>
                                        <label>{t('texto9')}</label>
                                    </div>
                                    <div className='box-input-group'>
                                        <label>{mayorCount}</label>
                                        <div onClick={handleMayorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                        <div onClick={handleMayorCount}><i class="fi fi-sr-square-plus"></i></div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className='input-group'>
                                <div className='content-input-group'>
                                    <div className='box-input-group'>
                                        <label>{t('texto10')}</label>
                                        <label>{t('texto11')}</label>
                                    </div>
                                    <div className='box-input-group'>
                                        <label>{infanteCount}</label>
                                        <div onClick={handleInfanteCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                        <div onClick={handleInfanteCount}><i class="fi fi-sr-square-plus"></i></div>                                        
                                    </div>
                                </div>
                                <div className='content-input-group'>
                                    <div className='box-input-group'>
                                        <label>{t('texto12')}</label>
                                        <label>{t('texto13')}</label>
                                    </div>
                                    <div className='box-input-group'>
                                        <label>{menorCount}</label>
                                        <div onClick={handleMenorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                        <div onClick={handleMenorCount}><i class="fi fi-sr-square-plus"></i></div>                                        
                                    </div>
                                </div>
                            </div>
                            <div className='input-group'>
                                <textarea placeholder={t('text18')} value={mensaje} onChange={(e) => setMensaje(e.target.value)}></textarea>
                            </div>
                            <div className='input-group'>
                            <label> 
                                <input type="checkbox" checked={aceptoPoliticas} onChange={handleCheckboxChange} />
                                {t('texto14')} <Link to="/aviso-de-privacidad">{t('texto15')}</Link>   
                            </label>

                            </div>
                            <div className='input-group'>
                                <button disabled={!aceptoPoliticas || !nombre || !telefono || !email} onClick={enviarCorreo}>
                                {t('texto16')}
                                </button>
                            </div>                  

                        <p>*{t('text17')}</p>
                    </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>

    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={rutas} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title1')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title1')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas_horario">{t('cta_btn1')}</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={destinos} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title2')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title2')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/destinos">{t('cta_btn2')}</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title3')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">{t('cta_btn3')}</a>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
    <Footer />
    </>
  )
}

// QuestionDetails.js

import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../../../css/answer.css'
import { useTranslation } from 'react-i18next';
import bookingIcon from '../../../assets/images/iconsPreguntas/calendario1.png'
import reservar  from "../../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import carga from '../../../assets/images/iconsPreguntas/barco-de-carga.png'
import avisos from '../../../assets/images//iconsPreguntas/megafono.png'
import pagos_facturas from '../../../assets/images/iconsPreguntas/factura.png'
import ShareButton from '../../ShareButton';
import Contactanos from '../../contacto/Contactanos';
import Footer from '../../Footer';


function DetailDocumentacion() {
    const { t } = useTranslation('pf_documentacion');
    const [modal, setModal] = useState(false);
  const { id } = useParams();


const questions = [
    {
        id: 1,
        text: t('pregunta1'),
        answer:( 
            <ul>
                <li>{t('respuesta1')}</li>
                <li>{t('respuesta2')}</li>
                <li>{t('respuesta3')}</li>
                <li>{t('respuesta4')}</li>
                <li>{t('respuesta5')}</li>               
            </ul>
        ),
    },
    {
        id: 2,
        text: t('pregunta2'),
        answer: t('pregunta6'),
    },
    {
        id: 3,
        text: t('pregunta3'),
        answer: t('pregunta7'),
    },
    {
        id: 4,
        text: t('pregunta4'),
        answer: t('pregunta8'),
    },
    {
        id: 5,
        text: t('pregunta5'),
        answer: ( 
            <>
            <p>{t('respuesta9')}</p>
            <ul>
                <li>{t('respuesta10')}</li>
            </ul>
            <p>{t('respuesta11')} </p>
            <p>{t('respuesta12')}</p>
            <p>{t('respuesta13')}</p>
            <ul>
                <li>{t('respuesta14')}</li>                                   
                <li>{t('respuesta15')}</li>
                <li>{t('respuesta16')}</li>
                <li>{t('respuesta17')}</li>
            </ul>
            <p>{t('respuesta18')}</p>
            
            </>
            
        ),
    },
 

];

const question = questions.find(q => q.id === parseInt(id));

if (!question) {
  return <div>Pregunta no encontrada</div>;
}
const shareUrl = `http://localhost:3000/preguntas-frecuentes-antes-viajar/${id}`;
const title = 'Preguntas frecuentes';

const showBoxShare = () => {
  setModal(!modal)
}


  return (

    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>{t('preguntas_frecuentes')}</h3>
                    <p>{t('preguntas_frecuentes_p')}</p>
                </div>                
            </div>
        </div>
    </section>

    <section className='container'>
        <div className='box_titulo_faqs '>
            <div className='lista_preguntas'>
                <div className='answer_pgunas_frcuentes'>
                    <h1>{question.text}</h1>
                    <p> {question.answer}</p>
                </div>
            </div>
            <div className='lista_temas'>
                <h3>Otros temas frecuentes</h3>
                <div className='box_lista_temas'>
                    <ul>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={bookingIcon} alt='antesViajar' />
                                <Link to="/antes-de-viajar-faqs">Antes de viajar</Link>
                            </div>
                        </li>
                        <li> 
                            <div className='content_lista_temas'>
                                <img src={reservar} alt='antesViajar' />
                                <Link to="/reserva">Reservar</Link> 
                            </div>
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={pagos_facturas} alt='antesViajar' />
                                <Link to="/pagos-facturas">Pagos y facturas</Link> 
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={carga} alt='antesViajar' />
                                <Link to="/carga">Carga</Link>  
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={avisos} alt='antesViajar' />
                                <Link to="/automovil">Automóvil</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div className='container'>
            <div className='box_share'>
                <div><p>¿Fue útil esta información?</p></div>     
                <div>
                    <div className='btn_share' onClick={showBoxShare}>Compartir <i class="fi fi-rr-share"></i></div>
                    {modal && (
                        <div className='box_share_options'>
                            <ShareButton url={shareUrl} title={title} />
                        </div>
                    )}
                </div>
                
            </div>
        </div>        
    </section>

    <Contactanos />
    <Footer />


    
    </>
  );
}



export default DetailDocumentacion;

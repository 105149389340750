import React, {useEffect, useState} from 'react'
import '../css/noticia_body.css'
import { Outlet, Link } from "react-router-dom";

export default function NoticiaBody() {
    const [noticias, setNoticias] = useState([]);

    const loadNoticias = async () => {
        try {
            //Localhost
           //const response = await fetch('http://localhost:3004/noticias');
            
            const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/noticias');
            const data = await response.json();
            console.log('Data: ', data);
            setNoticias(data);
          } catch (error) {
            console.error('Error fetching noticias:', error);
          }
    }

    useEffect(() =>{
        loadNoticias();
    },[])

    if (noticias.length === 0) {
        return <p>Loading...</p>; // Puedes mostrar un mensaje de carga mientras se obtienen las noticias.
    }

    // Filtra solo las noticias visibles
    const noticiasVisibles = noticias.filter(noticia => noticia.visible);


    // Toma la primera noticia visible como primeraNoticia
    const primeraNoticia = noticiasVisibles[18];
    const sideNoticias = noticiasVisibles.slice(16,18);
    //const otrasNoticias = noticiasVisibles.slice(3,7);
    const otrasNoticias = noticiasVisibles.slice(11,15);
    //const otrasNoticiasMobile = noticiasVisibles.slice(1, 4);
    const otrasNoticiasMobile = noticiasVisibles.slice(15, 19);
         
       
    return (
        <>

            <div className="d-none d-sm-none d-md-block">
                <div className="noticias_content_line">                  
                    <div className="noticia">
                        <Link to={`/noticia/${primeraNoticia.id}`}>
                            <div className='noticia_img_header_home' style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(./images/noticias/${primeraNoticia.photo_route_header})` }}></div>                           
                            <div className='noticias_text_home'>
                                <p><i class="fi fi-sr-bookmark"></i> {primeraNoticia.category.join(', ')}</p>
                                <h3>{primeraNoticia.title}</h3>                        
                            </div>  
                        </Link>                   
                    </div>                
                    <div className="noticias_home">
                        {sideNoticias
                        .map((noticia) => (
                        <div key={noticia.id} className="noticia-content-box">
                            <Link to={`/noticia/${noticia.id}`} key={noticia.id} >  
                                <div className="noticia-item">
                                    <div className='noticias_img_header_home' style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(./images/noticias/${noticia.photo_route_header})` }}></div>                                    
                                    <div className='noticias_text_home'>
                                        <p><i class="fi fi-sr-bookmark"></i> {noticia.category.join(', ')}</p>
                                        <h3>{noticia.title}</h3>                                
                                    </div>                        
                                </div>
                            </Link> 
                        </div>
                        ))}
                    </div>
                </div>  
                <div>
                <div className="noticias_home_horizontal">
                        {otrasNoticias
                        .map((noticia) => (
                        <div key={noticia.id} className="noticia-content-horizontal">
                            <Link to={`/noticia/${noticia.id}`} key={noticia.id} >  
                                <div className="noticia-item-horizontal">
                                    <div className='noticias_img_header_home_horizontal' style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)), url(./images/noticias/${noticia.photo_route_header})` }}></div>                                    
                                    <div className='noticias_text_home_horizontal'>
                                        <p><i class="fi fi-sr-bookmark"></i> {noticia.category.join(', ')}</p>
                                        <h3>{noticia.title}</h3>                                
                                    </div>          
                                </div>

                            </Link> 
                        </div>
                        ))}
                    </div>                      
                </div>              
            </div>     
            <div className="d-block d-sm-block d-md-none">
            <div className="noticias_home_mobile">
            {otrasNoticiasMobile.map((noticia) => (
              <div key={noticia.id} className="noticia-content-mobile">
                <Link to={`/noticia/${noticia.id}`} key={noticia.id}>
                  <div
                    className="noticia-item-mobile"
                    style={{
                      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)), url(./images/noticias/${noticia.photo_route_header})`,
                    }}
                  ></div>
                  <div className="noticias_text_home_mobile">
                    <p>
                      <i className="fi fi-sr-bookmark"></i> {noticia.category.join(', ')}
                    </p>
                    <h3 className='informacion-div'>{noticia.title}</h3>
                  </div>
                </Link>
              </div>
            ))}
            </div>
        </div>       
  
        <Outlet />    
        </>
        
    )
}

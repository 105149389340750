import React from 'react'
import '../../css/promocion.css'
import promo3 from '../../assets/images/promociones/promocion_ninos-cintillo.webp'
import lateralPromo1 from '../../assets/images/promociones/promo-abordo.png'
import lateralPromo2 from '../../assets/images/promociones/inapam.webp'
import { Outlet, Link } from "react-router-dom";
import whatsapp from '../../assets/images/chat.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
import Footer from '../../components/Footer'
import { useTranslation } from 'react-i18next';


export default function Promo3() {
    const targetDate = new Date('2023-12-31T23:59:59').getTime();
    const { t } = useTranslation('promo3');
  return (
   <>

    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                <h3>{t('title')}</h3>
                <p>{t('description')}</p>
                </div>                
            </div>
        </div>
    </section>   
    <section className='container'>
        <div className='banner-header-promo'>
            <img src={promo3} alt='buen-fin-bajaferries' />
        </div>
    </section>

    <section className='sectionPromo_content'>
        <div className='container'>
            <div className='box_promo'>
                <div className='content_text_promo'>
                    <div className='content_promo'>
                        
                        <p>{t('text1')}</p>

                        <p>{t('text3')}</p>
                        <div className='content_promo_btn'>                                                
                            <a className='cta_promo' href="tel:8003377437" rel="noopener noreferrer"><span >{t('btn1')} </span></a> 
                            <a target="_blank" className='cta_promo' rel='noopener noreferrer' href='https://wa.me/5218003377437?text=Me%20interesa%20una%20cotización'>{t('btn2')}  </a>
                        </div> 
                    </div>
                    
                    <div className='content_promo condiciones-promo'>
                        <h4>{t('text4')}</h4>
                        {/*<p>El tiempo se acaba, ¡Lugares limitados hasta agotar existencias!.</p>*/}
                        <ul>                            
                            <li>•  {t('text5')}</li>
                                 
                        </ul>
                    </div>
                    {/*<div className='content_promo btn-termino-condiciones'>
                    <Link to="/condiciones-de-servicio">Consulta términos y condiciones</Link> 
                    </div>*/}
                </div>
                <div className='content_contador_promo'>

                    <div className='content_promo'>
                        {/*}
                        <div className='content-isotipo'>
                            <img src={bfIso} alt='bf-isotipo'/>
                        </div>
                        <div>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                            </p>

                        </div>
                        <div>
                            <Temporizador targetDate={targetDate} />
                        </div>
                        */}
                        <h4>{t('text6')} </h4>
                        <div className='lateral-promo'>
                            <Link to="/promo-abordo">
                                <img src={lateralPromo1} alt='buen-fin-bajaferries' />
                            </Link> 
                        </div>
                        <div className='lateral-promo'>
                            <Link to="/promo-adulto-mayor">
                                <img src={lateralPromo2} alt='buen-fin-bajaferries' />
                            </Link> 
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>        
    </section>
    
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title1')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title1')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            
                            <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">{t('cta_btn1')}  </a>  
                           
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title2')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title2')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/antes-de-viajar">{t('cta_btn2')}  </Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title3')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">{t('cta_btn3')}</Link>                     
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />

    <Footer />
   
   </>
  )
}

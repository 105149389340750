import React from 'react'
import '../../css/destino.css'
import { Outlet, Link } from "react-router-dom";
import promociones from '../../assets/images/cta/promociones.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/pregutas.png'
import Footer from '../Footer'
import ImageSlider from '../sliders/SliderDestinos';
import {img1Maza, img2Maza, img3Maza, } from '../../assets/base64'
import { useTranslation } from 'react-i18next';
export default function Mazatlan() {
    const { t } = useTranslation('Mazatlan');
    
    const images = [
        {
            image: img1Maza,
            title: 'Acuario Mazatlán',
            text: 'Este recinto alberga más de 200 especies distintas siendo uno de los más grandes de Latinoamérica. Además, el acuario ofrece espectáculos impresionantes como el show de lobos marinos, de buceo, nado con tiburones y snorkel en el tanque de rayas.',
            subtext: 'Mazatlán, Sinaloa.',
        },
        {
            image: img2Maza,
            title: 'Las Labradas',
            text: 'Las Labradas es una zona arqueológica de grabados rupestres donde se pueden apreciar más de 700 figuras talladas en rocas volcánicas de al menos mil años de antigüedad. Estos misteriosos petroglifos situados junto al mar representan figuras humanas, animales, vegetales y también algunas abstractas; no hay dos figuras iguales en todo el lugar.',
            subtext: 'Mazatlán, Sinaloa.',
        },
        {
            image: img3Maza,
            title: 'El Faro',
            text: 'Ubicado en el Cerro del Crestón, es considerado como el faro natural más alto del mundo con un alcance luminoso de 48 millas náuticas. Es un recorrido de 30 minutos compuesto por 300 escalones.',
            subtext: 'Parque Natural Faro Mazatlán',
        },
        // Agrega más imágenes según sea necesario
      ];
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Mazatlán, Sinaloa.</h3>
                        <p>{t('mazatlan')}</p>
                    </div>                
                </div>
            </div>
        </section>
        <section>
            <ImageSlider images={images}/>
        </section>
        <section>            
            <div className='container'>
                <div className='destino_info_util'>
                    <div className='content_destinos_info'>
                        <div className='box_destinos_info'>
                            <h3>{t('text1')}</h3>
                            <ul>
                                <li><strong>{t('text2')}: </strong>{t('text3')}</li>
                                <li><strong>{t('text4')}: </strong> {t('text5')}</li>
                                <li><strong>{t('text6')}: </strong>{t('text7')}</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>{t('text8')}</h3>
                            <ul>
                                <li><strong>{t('text9')}: </strong>{t('text10')}.</li>
                                <li><strong>{t('text11')}: </strong> {t('text12')}</li>
                                <li><strong>{t('text13')}: </strong>{t('text14')}</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>{t('text15')}</h3>
                            <label>{t('text16')}:</label>
                            <ul>
                                <li><strong>{t('text17')}: </strong>{t('text18')}</li>
                                <li><strong>{t('text19')}: </strong>{t('text20')}</li>
                                <li><strong>{t('text21')}: </strong>{t('text22')}</li>                                
                            </ul>
                        </div>   
                        <div className='box_destinos_info'>
                            <h3>{t('text23')}</h3>
                            <ul>
                                <li><strong>{t('text24')}: </strong>{t('text25')}</li>
                                <li><strong>{t('text26')}: </strong>{t('text27')}</li>
                                <li><strong>{t('text28')}: </strong> {t('text29')}</li>                       
                            </ul>
                        </div>                 
                    </div>
                    <div className='content_destinos_info'>                    
                        <div className='box_destinos_info'>
                            <h3>{t('text30')}</h3>
                            <label>{t('text31')}:</label>
                            <ul>
                                <li><strong>{t('text32')}: </strong>{t('text33')}</li>
                                <li><strong>{t('text34')}: </strong>{t('text35')}</li>
                                <li><strong>{t('text36')}: </strong>{t('text37')}</li>
                                <li><strong>{t('text38')}: </strong>{t('text39')}</li>
                                <li><strong>{t('text40')}: </strong>{t('text41')}</li>
                                <li><strong>{t('text42')}: </strong>{t('text43')}</li>
                            </ul>
                        </div>
                        
                        <div className='box_destinos_info'>
                            <h3>{t('text44')}</h3>
                            <label>{t('text45')}:</label>
                            <ul>
                                <li><strong>{t('text46')}: </strong>{t('text47')}.</li>
                                <li><strong>{t('text48')}: </strong>{t('text49')}.</li>
                                <li><strong>{t('text50')}: </strong>{t('text51')}.</li>                       
                            </ul>
                            <h3>{t('text52')}:</h3>
                            <ul>
                                <li>{t('text53')}</li>                   
                            </ul>
                        </div>

                    </div>
                </div>
                
            </div>
        </section>
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Ver tarifas</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Promociones y Descuentos</h4>
                            </div>
                            <div>
                                <p>Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <Link to="/promociones">Ir ahora</Link>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
        <Footer />
        
    </>
  )
}
